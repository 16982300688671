import { ReCaptchaEnterprise } from './recaptchaV3.types';

declare global {
  interface Window {
    ng2recaptchaloaded: () => void;
  }
}

// declare var grecaptcha: ReCaptchaV2.ReCaptcha & {
//   enterprise: ReCaptchaV2.ReCaptcha;
// };

declare var grecaptcha: ReCaptchaEnterprise;

function loadV3Script(
  renderMode: 'explicit' | string,
  onLoaded: (grecaptcha: ReCaptchaEnterprise) => void,
  urlParams: string,
  url?: string,
  nonce?: string,
): void {
  if (typeof window === 'undefined' || typeof document === 'undefined') return;
  window.ng2recaptchaloaded = () => {
    onLoaded(grecaptcha);
  };

  const script = document.createElement('script');
  script.innerHTML = '';
  // const baseUrl = url || "https://www.google.com/recaptcha/api.js";
  const baseUrl = url || 'https://www.google.com/recaptcha/enterprise.js';

  script.src = `${baseUrl}?render=${renderMode}&onload=ng2recaptchaloaded${urlParams}`;
  if (nonce) {
    script.setAttribute('nonce', nonce);
  }
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

function loadCheckboxScript(
  onLoaded: (grecaptcha: ReCaptchaEnterprise) => void,
  url?: string,
): void {
  console.warn('loadCheckscript');
  if (typeof window === 'undefined' || typeof document === 'undefined') return;
  window.ng2recaptchaloaded = () => {
    onLoaded(grecaptcha);
  };
  const script = document.createElement('script');
  script.innerHTML = '';
  const baseUrl = url || 'https://www.google.com/recaptcha/enterprise.js';

  // script.src = `${baseUrl}?onload=ng2recaptchaloaded${urlParams}`;
  script.src = `${baseUrl}?onload=ng2recaptchaloaded`;
  // if (nonce) {
  //   script.setAttribute('nonce', nonce);
  // }
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
}

export const loader = { loadV3Script, loadCheckboxScript };
