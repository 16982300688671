import { DatePipe } from '@angular/common';
import {
  Component,
  effect,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { EventService } from '@jfw-library/ecommerce/core';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
  Look,
  UserEvent,
} from 'common-types';
import {
  from,
  map,
  Observable,
  of,
  shareReplay,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { EcommerceSharedModuleEventService } from '../../services/event/shared/ecommerce-shared-module-event.service';
import { LookService } from '../../services/look/look.service';
import {
  EventModalComponentData,
  NewEventModalComponentData,
} from '../../types';
import { NewEventModalComponent } from '../new-event-modal/new-event-modal.component';

interface EventForm {
  lookName: FormControl<string>;
  eventSelect: FormControl<string | null>;
  eventButtonToggle: FormControl<string | null>;
}

export type EventModalResult = { eventId: string | null };

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss'],
})
export class EventModalComponent implements OnInit, OnDestroy {
  readonly allowEventCreation = this.environment.dealerPortal ? false : true;
  showSpinner = signal(true);
  disableForm = effect(() => {
    const showSpinner = this.showSpinner();
    if (showSpinner) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  });
  existingEvents$: Observable<UserEvent[]>;
  useEventSelect$: Observable<boolean>;
  noEventSelected = false;
  eventId: string | null = null;
  subscription = new Subscription();

  form = new FormGroup<EventForm>({
    lookName: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    eventSelect: new FormControl(''),
    eventButtonToggle: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: EventModalComponentData,
    @Inject('environment')
    private readonly environment:
      | EcommerceMainEnvironment
      | DealerPortalEnvironment,
    public dialogRef: MatDialogRef<EventModalComponent>,
    public dialog: MatDialog,
    private eventService: EventService,
    private sharedEventService: EcommerceSharedModuleEventService,
    private lookService: LookService,
  ) {
    this.existingEvents$ = (
      this.data?.futureUserEvents
        ? of(this.data.futureUserEvents)
        : from(this.sharedEventService.getFutureUserEventsPromise(true)).pipe(
            map((data) => data ?? []),
          )
    ).pipe(
      map((events) =>
        events
          .filter((userEvent: UserEvent) => userEvent.isOrganizer) // only include events where the user is the organizer
          .sort((a, b) => a.eventDate - b.eventDate),
      ), // sort the events by date
      // tap(() => this.showSpinner = false),
      shareReplay(1),
    );

    this.useEventSelect$ = this.existingEvents$.pipe(
      map((events) => events.length >= 3),
      tap(() => this.showSpinner.set(false)),
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.form.valueChanges
        .pipe(
          withLatestFrom(this.useEventSelect$),
          tap(([_, useEventSelect]) => this.setEventId(useEventSelect)),
        )
        .subscribe(),
    );

    // const { futureUserEvents } = this.data ??
    // if (futureUserEvents.length === 0) {
    //   console.log("No future user events received in EventModalComponent");
    //   this.showSpinner = false;
    //   return;
    // }

    // this.existingEvents = this.getOrganizerUserEvents(futureUserEvents).sort(
    //   (a: UserEvent, b: UserEvent) => a.eventDate - b.eventDate
    // );

    // this.useEventSelect = this.existingEvents.length >= 3;

    if (this.data.newLook.title !== 'Untitled Look') {
      this.form.controls.lookName.setValue(this.data.newLook.title);
    }

    // this.showSpinner = false;

    // this.sharedEventService.getFutureUserEventsPromise(true).then((data) => {
    //   if (data === undefined) {
    //     this.showSpinner = false;
    //     return;
    //   }

    //   this.existingEvents = data;

    //   this.getOrganizerUserEvents();

    //   setTimeout(() => {
    //     if (this.existingEvents.length < 3) {
    //       this.useEventSelect = false;
    //     } else {
    //       this.useEventSelect = true;
    //     }

    //     // Sort the events.  This may end up in business rules.
    //     this.existingEvents = this.existingEvents.sort(
    //       (a: UserEvent, b: UserEvent) => a.eventDate - b.eventDate
    //     );
    //     this.showSpinner = false;
    //   });
    //   if (this.data.newLook.title !== 'Untitled Look') {
    //     this.form.controls.lookName.setValue(this.data.newLook.title);
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // getOrganizerUserEvents(existingEvents: UserEvent[]) {
  //   return existingEvents.filter((userEvent: UserEvent) => {
  //     return userEvent.isOrganizer;
  //   });
  // }

  formatEventWithDate(event: UserEvent): string {
    const datePipe = new DatePipe('en-US');
    if (event.eventName.length > 14) {
      return (
        event.eventName.slice(0, 14) +
        '... ' +
        datePipe.transform(event.eventDate * 1000, 'M/d/yyyy')
      );
    } else {
      return (
        event.eventName +
        ' ' +
        datePipe.transform(event.eventDate * 1000, 'M/d/yyyy')
      );
    }
  }

  showEventError(): boolean {
    return this.noEventSelected && !this.eventId;
  }

  setEventId(useEventSelect: boolean): void {
    if (useEventSelect) {
      this.eventId = this.form.controls.eventSelect.value;
    } else {
      this.eventId = this.form.controls.eventButtonToggle.value;
    }
  }

  async onNew(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const newLook: Look = {
      ...this.data.newLook,
      title: this.form.controls.lookName.value,
    };

    /**
     * Note this is continuation from EventModal
     *  If the EventModal is open while this is open, after this closes we also close EventModal.
     *  This signifies that the (selectedEvent) is populated accordingly and will saveLook to that event.
     *
     *  */
    const newEventRef = await this.dialog.open<
      NewEventModalComponent,
      NewEventModalComponentData
    >(NewEventModalComponent, {
      data: {
        newLook,
        futureUserEvents: this.data.futureUserEvents,
        redirectToEvent: true,
        parentDialogRef: this.dialogRef,
      },
      autoFocus: false,
      width: '340px',
      maxWidth: '340px',
      disableClose: true,
    });
  }

  async onExisting(): Promise<void> {
    let valid = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      valid = false;
    }

    if (!this.eventId) {
      this.noEventSelected = true;
      valid = false;
    }

    if (valid) {
      if (!this.eventId) {
        return;
      }

      const newLook: Look = {
        ...this.data.newLook,
        title: this.form.controls.lookName.value,
      };

      try {
        this.showSpinner.set(true);
        const event = await this.eventService.setSelectedEvent(
          this.eventId,
          'EventModalComponent -- onExisting()',
        );

        // this.data.newLook.title = this.form.controls.lookName.value;
        console.log('Saving look to event from EventModalComponent');
        // await this.lookService.saveLookToEvent(this.data.newLook, event);
        await this.lookService.saveLookToEvent(newLook, event);
      } catch (error) {
        console.error(
          'Error saving look to event from EventModalComponent',
          error,
        );
        this.showSpinner.set(false);
      }
      this.showSpinner.set(false);
      this.dialogRef.close({ eventId: this.eventId });
    }
  }
}
