import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  NgZone,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ReCaptchaEnterprise } from './recaptchaV3.types';

import { loader } from './load-script';
import {
  ActionBacklogEntry,
  OnExecuteData,
  OnExecuteErrorData,
} from './recaptcha-services.types';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_CHECKBOX_SITE_KEY,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_NONCE,
} from './tokens';

export { OnExecuteData, OnExecuteErrorData };

declare var grecaptcha: ReCaptchaEnterprise;

@Injectable()
export class ReCaptchaCheckboxService {
  /** @internal */
  private readonly isBrowser: boolean;
  /** @internal */
  private readonly siteKey: string;
  /** @internal */
  private readonly zone: NgZone;
  /** @internal */
  private actionBacklog: ActionBacklogEntry[] | undefined;
  /** @internal */
  private nonce: string | undefined;
  /** @internal */
  private language?: string;
  /** @internal */
  private baseUrl: string | undefined;
  /** @internal */
  private grecaptcha: ReCaptchaEnterprise | undefined;
  /** @internal */
  private action: string | undefined;

  /** @internal */
  private onExecuteSubject: Subject<OnExecuteData> | undefined;
  /** @internal */
  private onExecuteErrorSubject: Subject<OnExecuteErrorData> | undefined;
  /** @internal */
  private onExecuteObservable: Observable<OnExecuteData> | undefined;
  /** @internal */
  private onExecuteErrorObservable: Observable<OnExecuteErrorData> | undefined;

  constructor(
    zone: NgZone,
    @Inject(RECAPTCHA_CHECKBOX_SITE_KEY) siteKey: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject(RECAPTCHA_BASE_URL) baseUrl?: string,
    @Optional() @Inject(RECAPTCHA_NONCE) nonce?: string,
    @Optional() @Inject(RECAPTCHA_LANGUAGE) language?: string,
  ) {
    this.zone = zone;
    this.isBrowser = isPlatformBrowser(platformId);
    this.siteKey = siteKey;
    this.nonce = nonce;
    this.language = language;
    this.baseUrl = baseUrl;

    this.init();
  }

  public get onExecute$(): Observable<OnExecuteData> {
    if (!this.onExecuteSubject || !this.onExecuteObservable) {
      this.onExecuteSubject = new Subject<OnExecuteData>();
      this.onExecuteObservable = this.onExecuteSubject.asObservable();
    }

    return this.onExecuteObservable
      .pipe
      // tap((data) => {
      //   console.log("Recaptcha data: ", data);
      // })
      ();
  }

  public get onExecuteError$(): Observable<OnExecuteErrorData> {
    if (!this.onExecuteErrorSubject || !this.onExecuteErrorObservable) {
      this.onExecuteErrorSubject = new Subject<OnExecuteErrorData>();
      this.onExecuteErrorObservable = this.onExecuteErrorSubject.asObservable();
    }

    return this.onExecuteErrorObservable;
  }

  public setAction(action: string) {
    this.action = action;
  }

  /**
   * Renders the provided container element as a reCAPTCHA widget (defaults to element with id 'recaptcha') and returns the ID of the newly created widget.
   * This should be called in the ngAfterViewInit lifecycle hook of the container component to avoid attempting to render in an element that is not yet present in the DOM.
   * @param container The HTML element to render the reCAPTCHA widget. Specify either the ID of the container (string) or the DOM element itself.
   * @returns The ID of the newly created widget.
   */
  private renderCheckbox(
    container:
      | HTMLElement
      | string = 'recaptcha' /*options: ReCaptchaEnterprise.RenderOptions*/,
  ) {
    if (this.grecaptcha) {
      // console.log("Rendering recaptcha with siteKey: ", this.siteKey);
      const id = this.grecaptcha.enterprise.render(container, {
        sitekey: this.siteKey,
        callback: (token: string) => {
          if (this.onExecuteSubject) {
            this.onExecuteSubject.next({
              action: this.action ?? 'UNDEFINED-ACTION',
              token,
            });
          }
        },
        'expired-callback': () => {
          if (this.onExecuteSubject) {
            this.onExecuteSubject.next({
              action: this.action ?? 'VERIFICATION-EXPIRED',
              token: '',
            });
          }
        },
      });
      if (this.actionBacklog && this.actionBacklog.length > 0) {
        this.actionBacklog.forEach(([action, subject]) =>
          this.executeActionWithSubject(action, subject),
        );
        this.actionBacklog = undefined;
      }
      return id;
    } else {
      throw new Error("reCAPTCHA not loaded yet. Can't render Checkbox");
    }
  }

  /** @internal */
  private executeActionWithSubject(
    action: string,
    subject: Subject<string>,
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      this.zone.run(() => {
        subject.error(error);
        if (this.onExecuteErrorSubject) {
          // We don't know any better at this point, unfortunately, so have to resort to `any`
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.onExecuteErrorSubject.next({ action, error });
        }
      });
    };

    this.zone.runOutsideAngular(() => {
      if (this.grecaptcha) {
        // console.log("running grecaptcha with siteKey: ", this.siteKey)
        try {
          this.grecaptcha.enterprise
            .execute(this.siteKey, { action })
            .then((token: string) => {
              this.zone.run(() => {
                subject.next(token);
                subject.complete();
                if (this.onExecuteSubject) {
                  this.onExecuteSubject.next({ action, token });
                }
              });
            }, onError);
        } catch (e) {
          onError(e);
        }
      } else {
        console.error('reCAPTCHA v3 not loaded yet');
      }
    });
  }

  /** @internal */
  private init() {
    // console.log("Site key in ReCaptcha Checkbox Service: ", this.siteKey)
    if (this.isBrowser) {
      // console.log("isBrowser")
      if ('grecaptcha' in window) {
        console.log('grecaptcha in window', window.grecaptcha);
        this.onLoadComplete(grecaptcha);
      } else {
        const langParam = this.language ? '&hl=' + this.language : '';
        loader.loadCheckboxScript(this.onLoadComplete, this.baseUrl);
      }
    }
  }

  /** @internal */
  private onLoadComplete = (grecaptcha: ReCaptchaEnterprise) => {
    grecaptcha.enterprise.ready(() => {
      console.log('onLoadComplete, rendering recaptcha');
      this.grecaptcha = grecaptcha;
      // if (this.action) {
      //   grecaptcha.enterprise.execute(this.siteKey, {
      //     action: this.action,
      //   });
      // }
      this.renderCheckbox();
    });
    /* moved to renderCheckbox function
    this.grecaptcha.enterprise.render('recaptcha', {
      sitekey: this.siteKey,
      callback: (token: string) => {
        if (this.onExecuteSubject) {
          this.onExecuteSubject.next({ action: this.action ?? "UNDEFINED-ACTION", token });
        }
      },
      'expired-callback': () => {
        if (this.onExecuteSubject) {
          this.onExecuteSubject.next({ action: this.action ?? "VERIFICATION-EXPIRED", token: "" });
        }
      }
    });
    if (this.actionBacklog && this.actionBacklog.length > 0) {
      this.actionBacklog.forEach(([action, subject]) => this.executeActionWithSubject(action, subject));
      this.actionBacklog = undefined;
    } */
  };
}
