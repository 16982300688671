import { Injectable, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AngularFireAuthService {
  private auth = inject(Auth);

  public authState(): Observable<User | null> {
    return authState(this.auth);
  }
}
