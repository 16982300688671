<button
  [style.visibility]="hidden ? 'hidden' : 'visible'"
  attr.aria-label="{{ ariaLabel }}"
  (click)="onClick()"
  [disabled]="disabled"
  [style.cursor]="disabled ? 'default' : 'pointer'"
>
  <div
    class="arrow"
    [ngClass]="{
      up: direction === 'up',
      down: direction === 'down',
      left: direction === 'left',
      right: direction === 'right',
      enabled: !disabled,
      disabled: disabled
    }"
  >
    <div class="line-1"></div>
    <div class="line-2"></div>
  </div>
</button>
