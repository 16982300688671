import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { generateUUID, getMeasurementsFromWidget } from 'business-logic';
import { Measurements } from 'common-types';
import { Subject, Subscription } from 'rxjs';
import { MeasurementService } from '../../services/measurement/measurement.service';

@Component({
  selector: 'app-measurements-tool',
  templateUrl: './measurements-tool.component.html',
  styleUrls: ['./measurements-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasurementsToolComponent implements OnInit, OnDestroy {
  @Input() publicKey: string = '';
  @Input() email: string = '';
  @Input() memberId: string = '';
  @Input() shoeSize?: string = '';
  @Input() shoeFieldRequired: boolean = false;
  @Input() returnUrl: string = '';
  @Output() shoeSizeFieldWarn = new EventEmitter<boolean>();
  initialized: boolean = false;
  vendorEmail: string = '';
  isMobile: boolean = false;
  measurements$ = new Subject<Measurements>();
  subscription = new Subscription();
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  index: string = generateUUID(this.isBrowser);

  constructor(
    // private renderer: Renderer2,
    // @Inject(DOCUMENT) private document: any,
    private measurementService: MeasurementService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  async ngOnInit() {
    this.initButton();

    // Listen for events coming from the widget.
    if (this.isBrowser) {
      window.addEventListener('message', async (msg) => {
        const { command } = msg.data;
        switch (command) {
          case 'saia-pf-widget.close':
            break;
          case 'saia-pf-widget.data':
            if (msg.data.data && msg.data.data.volume_params) {
              msg.data.data.shoeSize = this.shoeSize;
              const measurements = getMeasurementsFromWidget(msg.data.data);
              this.measurements$.next(measurements);
              // await this.getMeasurements();
            }
            break;
          default:
            break;
        }
        // This can be useful when adding functionality, but it is
        // verbose in that it is called quite often.
        // console.log(`3dlook Command was received: ${command ?? 'no command'}`);
      });
    }

    this.subscription.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe({
        next: (result) => (this.isMobile = result.matches),
      }),
    );

    await this.getMeasurements();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async getMeasurements() {
    if (this.memberId === this.getLocalStorageMemberId3d()) {
      await this.measurementService
        .getMeasurements(`${this.memberId}`)
        .subscribe((data) => {
          data.shoeSize = this.shoeSize;
          this.measurements$.next(data);
          // this.removeLocalStorageMemberId3d();
        });
    }
  }

  async initButton(): Promise<void> {
    const SaiaMTMButton = (window as any).SaiaMTMButton;

    try {
      const publicKey = this.publicKey;

      const [isWidgetAllowed, customSettings] =
        await SaiaMTMButton.getWidgetInfo(publicKey);

      // await this.measurementService
      //   .getMeasurementReference(this.memberId, this.email, this.shoeSize)
      //   .subscribe((data) => {
      //     console.log(`data is ${JSON.stringify(data)}`);
      //     this.vendorEmail = data.vendorEmail;

      // });
      if (isWidgetAllowed) {
        var settings: any = {
          container: `.saia-widget-container-${this.index}`,
          publicKey,
          widgetUrl: 'https://mtm-widget.3dlook.me',
          customSettings,
          returnUrlDesktop: false,
          defaultValues: {
            // email: this.vendorEmail,
            email: `${this.memberId}@nomail.com`,
          },
          disableInput: { email: true },
          disableScreen: { email: true },
        };

        // Using undefined, null, or empty string to signify the widget should return to calling page.
        if (typeof this.returnUrl != 'undefined' && this.returnUrl) {
          settings.returnUrl = this.returnUrl;
          console.log(
            'ReturnUrl was not empty, so it was placed in settings: ',
            settings,
          );
        } else {
          console.log(
            'ReturnUrl was falsey, so not put in settings: ',
            settings,
          );
        }

        const button = new SaiaMTMButton(settings);
        button.init(publicKey);
      }
    } catch (err) {
      console.error(err);
    }
  }

  shoeValidCheck(): void {
    if (this.shoeSize === '' && this.shoeFieldRequired === true) {
      this.shoeSizeFieldWarn.emit(true);
    }
  }

  setLocalStorageMemberId3d(): void {
    if (this.isMobile) {
      localStorage.setItem('3dLooksMobile', `${this.isMobile}`);
    }
    localStorage.setItem('3dLookMemberId', this.memberId);
  }

  removeLocalStorageMemberId3d(): void {
    localStorage.removeItem('3dLookMemberId');
  }

  getLocalStorageMemberId3d(): string | undefined {
    return localStorage.getItem('3dLookMemberId') ?? undefined;
  }
}
