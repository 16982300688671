import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MeasurementsForm } from '@jfw-library/ecommerce/zod-forms';
import {
  ChestRange,
  //CoatSizeRange,
  HeightRange,
  HipRange,
  NeckRange,
  OutseamRange,
  OverarmRange,
  ShoeSizeRange,
  SleeveRange,
  StomachRange,
  WaistRange,
  WeightRange,
  findClosestMeasurement,
  getLocalStorageMemberId3d,
} from 'business-logic';
import { Event, EventMember, Measurements, StyleType } from 'common-types';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MeasurementsToolComponent } from '../measurements-tool/measurements-tool.component';

const Ranges: Record<
  keyof Omit<MeasurementsForm, 'derivedFrom' | 'memberId'>,
  any
> = {
  height: HeightRange,
  weight: WeightRange,
  //coatSize: CoatSizeRange,
  chest: ChestRange,
  overarm: OverarmRange,
  stomach: StomachRange,
  waist: WaistRange,
  hip: HipRange,
  outseam: OutseamRange,
  neck: NeckRange,
  sleeve: SleeveRange,
  shoeSize: ShoeSizeRange,
};

@Component({
  selector: 'app-finalize-measurements',
  templateUrl: './finalize-measurements.component.html',
  styleUrls: ['./finalize-measurements.component.scss'],
})
export class FinalizeMeasurementsComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  // @ViewChild('height') heightView!: MatAutocomplete;
  // @ViewChild('weight') weightView!: MatAutocomplete;
  // //@ViewChild('coat') coatSizeView!: MatAutocomplete;
  // @ViewChild('chest') chestView!: MatAutocomplete;
  // @ViewChild('overarm') overarmView!: MatAutocomplete;
  // @ViewChild('stomach') stomachView!: MatAutocomplete;
  // @ViewChild('waist') waistView!: MatAutocomplete;
  // @ViewChild('hip') hipView!: MatAutocomplete;
  // @ViewChild('outseam') outseamView!: MatAutocomplete;
  // @ViewChild('neck') neckView!: MatAutocomplete;
  // @ViewChild('sleeve') sleeveView!: MatAutocomplete;
  // @ViewChild('shoeSize') shoeSizeView!: MatAutocomplete;

  @ViewChildren(MeasurementsToolComponent)
  widgets!: QueryList<MeasurementsToolComponent>;
  @Input() updatingMeasurements!: boolean;
  @Input() member!: EventMember;
  @Input() event: Event | undefined;
  @Input() reviewMeasurements = false;
  @Input() skipStepOption = true;
  @Input() submitOption = false;
  @Input() measurementForm!: FormGroup<MeasurementsForm>;
  @Output() updatedMember = new EventEmitter<EventMember>();
  submitted: boolean = false;
  measurementFormError!: string;
  derivedFrom: string = '';
  threeDLookPublicKey: string = this.environment.threeDLook.publicKey;
  email: string = '';
  memberId: string = '';
  shoeSize: string = '';
  returnUrl: string = '';
  digitalShoeField: boolean = false;
  shoeSizeFormControl = new FormControl('');
  heightFilteredOptions!: Observable<string[]> | undefined;
  weightFilteredOptions!: Observable<number[]> | undefined;
  //coatSizeFilteredOptions!: Observable<string[]> | undefined;
  chestFilteredOptions!: Observable<number[]> | undefined;
  overarmFilteredOptions!: Observable<number[]> | undefined;
  stomachFilteredOptions!: Observable<number[]> | undefined;
  waistFilteredOptions!: Observable<number[]> | undefined;
  hipFilteredOptions!: Observable<number[]> | undefined;
  outseamFilteredOptions!: Observable<number[]> | undefined;
  neckFilteredOptions!: Observable<number[]> | undefined;
  sleeveFilteredOptions!: Observable<number[]> | undefined;
  shoeSizeFilteredOptions!: Observable<string[]> | undefined;
  shoeSizeFieldWarn: boolean = false;
  subscription = new Subscription();
  submitEnabled: boolean = false;
  form = this.measurementForm;

  constructor(
    @Inject('environment') private environment: any,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.initMeasurement();
  }

  ngAfterViewInit(): void {
    this.widgets.forEach((widget) => {
      console.log(
        `3DLook widget is defined: email:${widget.email}   returnURL:${widget.returnUrl ?? 'no return url'
        }`
      );

      this.subscription.add(
        widget.measurements$.subscribe((m) => {
          try {
            console.log(`3DLook measurement subscription received`);
            if (
              this.member.id !== undefined &&
              this.member.id === getLocalStorageMemberId3d()
            ) {
              console.log(
                `====================INCOMING==============${JSON.stringify(m)}`
              );

              const formData = {
                memberId: this.member.id,
                derivedFrom: m?.derivedFrom ?? '',
                height: m?.height ?? '',
                weight: findClosestMeasurement(WeightRange, m.weight!),
                //coatSize: m?.coatSize ?? null,
                chest: findClosestMeasurement(ChestRange, m.chest!),
                overarm: findClosestMeasurement(OverarmRange, m.overarm!),
                stomach: findClosestMeasurement(StomachRange, m.stomach!),
                waist: findClosestMeasurement(WaistRange, m.waist!),
                hip: findClosestMeasurement(HipRange, m.hip!),
                outseam: findClosestMeasurement(OutseamRange, m.outseam!),
                neck: findClosestMeasurement(NeckRange, m.neck!),
                sleeve: findClosestMeasurement(SleeveRange, m.sleeve!),
                shoeSize: m?.shoeSize ?? this.shoeSizeFormControl.value ?? null,
              };

              console.log(
                `=================OUTGOING=================${JSON.stringify(
                  formData
                )}`
              );
              this.form.setValue(formData);
              this.submitEnabled = true;
            }
          } catch (error) {
            console.error(error);
          }
        })
      );
    });
  }

  findClosestMeasurement(range: number[], measurement: number): number {
    return findClosestMeasurement(range, measurement);
  }

  closePreviousPanel(
    event: any,
    prevAutCompletePanelTrigger: MatAutocompleteTrigger
  ) {
    event.stopPropagation();
    prevAutCompletePanelTrigger.closePanel();
  }

  ngOnInit(): void { }

  initMeasurement(): void {
    this.memberId = this.member.id!;
    this.email = this.member.email!;
    this.setReturnUrl();

    this.form = this.measurementForm;
    if (this.member.measurements?.submitted === true && this.form) {
      this.submitted = true;
      // this.derivedFrom = 'complete';
      this.form.disable();
    }
    if (this.form !== undefined) {
      this.derivedFrom = this.member.measurements?.derivedFrom ?? '';
      if (this.hasLocalStorageMeasurements(this.member.id!)) {
        console.log(
          'Setting form from localStorage for member: ',
          this.member.id!
        );
        this.setLocalValue(this.member.id!);
      } else if (this.member.id !== undefined) {
        console.log(
          'Setting form with member.measurements for member: ',
          this.member.id!
        );
        this.form.setValue({
          memberId: this.member.id,
          derivedFrom: this.member.measurements?.derivedFrom ?? '',
          height: this.member.measurements?.height ?? null,
          weight: this.member.measurements?.weight ?? null,
          //coatSize: this.member.measurements?.coatSize ?? null,
          chest: this.member.measurements?.chest ?? null,
          overarm: this.member.measurements?.overarm ?? null,
          stomach: this.member.measurements?.stomach ?? null,
          waist: this.member.measurements?.waist ?? null,
          hip: this.member.measurements?.hip ?? null,
          outseam: this.member.measurements?.outseam ?? null,
          neck: this.member.measurements?.neck ?? null,
          sleeve: this.member.measurements?.sleeve ?? null,
          shoeSize: this.member.measurements?.shoeSize ?? null,
        });
        if (this.hasShoesInLooks()) {
          this.form.controls.shoeSize.setValue('');
        }
      }
    }

    if (this.form) {
      if (this.hasShoesInLooks()) {
        this.shoeSizeFormControl = this.form.controls.shoeSize;
      }

      this.subscription.add(
        this.form.valueChanges.subscribe({
          next: () => this.onUpdate(),
        })
      );

      this.heightFilteredOptions = this.getFilteredOptions(
        'height',
        this.member.measurements?.height
      );
      this.weightFilteredOptions = this.getFilteredOptions(
        'weight',
        this.member.measurements?.weight
      );
      //this.coatSizeFilteredOptions = this.getFilteredOptions('coatSize', this.member.measurements?.coatSize);
      this.chestFilteredOptions = this.getFilteredOptions(
        'chest',
        this.member.measurements?.chest
      );
      this.overarmFilteredOptions = this.getFilteredOptions(
        'overarm',
        this.member.measurements?.overarm
      );
      this.stomachFilteredOptions = this.getFilteredOptions(
        'stomach',
        this.member.measurements?.stomach
      );
      this.waistFilteredOptions = this.getFilteredOptions(
        'waist',
        this.member.measurements?.waist
      );
      this.hipFilteredOptions = this.getFilteredOptions(
        'hip',
        this.member.measurements?.hip
      );
      this.outseamFilteredOptions = this.getFilteredOptions(
        'outseam',
        this.member.measurements?.outseam
      );
      this.neckFilteredOptions = this.getFilteredOptions(
        'neck',
        this.member.measurements?.neck
      );
      this.sleeveFilteredOptions = this.getFilteredOptions(
        'sleeve',
        this.member.measurements?.sleeve
      );
      this.shoeSizeFilteredOptions = this.getFilteredOptions(
        'shoeSize',
        this.member.measurements?.shoeSize
      );

      this.setDerivedFrom();
    }
  }

  getFilteredOptions(
    controlName: keyof MeasurementsForm,
    initialValue: string | number | null | undefined
  ) {
    const control = this.form.get(controlName);
    if (!control) throw new Error(`Control ${controlName} not found in form`);
    const range =
      Ranges[
      controlName as keyof Omit<typeof Ranges, 'derivedFrom' | 'memberId'>
      ];
    if (!range) throw new Error(`Range for ${controlName} not found`);
    return control.valueChanges.pipe(
      startWith(initialValue),
      map((value: string | number | null | undefined) => {
        if (value === null || value === undefined || value === '') return range;
        const valueIsString = typeof value === 'string';
        const valueIsNumber = typeof value === 'number';
        const options = valueIsString
          ? range.filter((option: string) =>
            option.toLowerCase().startsWith(value ?? '')
          )
          : valueIsNumber
            ? range.filter((option: number) =>
              option.toString().toLowerCase().startsWith(value.toString())
            )
            : [];
        return options.length > 0 ? options : [''];
      })
    );
  }

  private setReturnUrl() {
    // If starting on Mobile, leave returnurl blank as this will allow the user to continue cart on the mobile device.
    // If starting on Desktop, provide the URL to be invoked on mobile when the widget completes.

    const userStartedOnMobile = this.breakpointObserver.isMatched(
      Breakpoints.XSmall
    );

    // The following has the widget go to the event with eventid and memberid settings, but would require a login if
    // first time the user visited the site on the mobile device.
    // this.returnUrl = isMobile ? '' : `${this.environment.threeDLook.returnUrl}/?eventId=${this.event.id}&memberId=${this.memberId}`;

    // This sends user to a landing page.
    this.returnUrl = userStartedOnMobile
      ? ''
      : `${this.environment.threeDLook.returnUrl}`;
  }

  onClose(controlName: keyof typeof Ranges): void {
    console.log('onCloseAutocomplete: ', controlName);
    const control = this.form.controls[controlName];
    if (!control) return;
    const value = control.value;
    if (value === null || value === undefined) return;
    const range = Ranges[controlName];
    const isInRange = range.includes(value);
    if (!isInRange) {
      console.log(
        'NOT IN RANGE measurement: ',
        controlName,
        control.value,
        ' - resetting to null'
      );
      control.setValue(null);
    } else {
      console.log('VALID measurement: ', controlName, control.value);
    }
  }
  // heightBlur(): void {
  //   let height = this.form.get('height')?.getRawValue();
  //   if (height !== null && height !== undefined) {
  //     if (!HeightRange.includes(height) && !this.heightView.isOpen) {
  //       this.form.controls.height.setValue(null);
  //     }
  //   }
  // }

  // weightBlur(): void {
  //   let weight = this.form.get('weight')?.getRawValue();
  //   if (weight !== null && weight !== undefined) {
  //     if (!WeightRange.includes(weight) && !this.weightView.isOpen) {
  //       this.form.controls.weight.setValue(null);
  //     }
  //   }
  // }
  /*
  coatBlur(): void {
    let coatSize = this.form.get('coatSize')?.getRawValue();
    if (coatSize !== null && coatSize !== undefined) {
      if (!CoatSizeRange.includes(coatSize) && !this.coatSizeView.isOpen) {
        this.form.controls.coatSize.setValue(null);
      }
    }
  }
  */

  // chestBlur(): void {
  //   let chest = this.form.get('chest')?.getRawValue();
  //   if (chest !== null && chest !== undefined) {
  //     if (!ChestRange.includes(chest) && !this.chestView.isOpen) {
  //       this.form.controls.chest.setValue(null);
  //     }
  //   }
  // }

  // overArmBlur(): void {
  //   let overarm = this.form.get('overarm')?.getRawValue();
  //   if (overarm !== null && overarm !== undefined) {
  //     if (!OverarmRange.includes(overarm) && !this.overarmView.isOpen) {
  //       this.form.controls.overarm.setValue(null);
  //     }
  //   }
  // }

  // stomachBlur(): void {
  //   let stomach = this.form.get('stomach')?.getRawValue();
  //   if (stomach !== null && stomach !== undefined) {
  //     if (!StomachRange.includes(stomach) && !this.stomachView.isOpen) {
  //       this.form.controls.stomach.setValue(null);
  //     }
  //   }
  // }

  // waistBlur(): void {
  //   let waist = this.form.get('waist')?.getRawValue();
  //   if (waist !== null && waist !== undefined) {
  //     if (!WaistRange.includes(waist) && !this.waistView.isOpen) {
  //       this.form.controls.waist.setValue(null);
  //     }
  //   }
  // }

  // hipBlur(): void {
  //   let hip = this.form.get('hip')?.getRawValue();
  //   if (hip !== null && hip !== undefined) {
  //     if (!HipRange.includes(hip) && !this.hipView.isOpen) {
  //       this.form.controls.hip.setValue(null);
  //     }
  //   }
  // }

  // outseamBlur(): void {
  //   let outseam = this.form.get('outseam')?.getRawValue();
  //   if (outseam !== null && outseam !== undefined) {
  //     if (!OutseamRange.includes(outseam) && !this.outseamView.isOpen) {
  //       this.form.controls.outseam.setValue(null);
  //     }
  //   }
  // }

  // neckBlur(): void {
  //   let neck = this.form.get('neck')?.getRawValue();
  //   if (neck !== null && neck !== undefined) {
  //     if (!NeckRange.includes(neck) && !this.neckView.isOpen) {
  //       this.form.controls.neck.setValue(null);
  //     }
  //   }
  // }

  // sleeveBlur(): void {
  //   let sleeve = this.form.get('sleeve')?.getRawValue();
  //   if (sleeve !== null && sleeve !== undefined) {
  //     if (!SleeveRange.includes(sleeve) && !this.sleeveView.isOpen) {
  //       this.form.controls.sleeve.setValue(null);
  //     }
  //   }
  // }

  // shoeBlur(): void {
  //   let shoeSize = this.form.get('shoeSize')?.getRawValue();
  //   if (shoeSize !== null && shoeSize !== undefined) {
  //     // && !this.shoeSizeView.isOpen
  //     if (!ShoeSizeRange.includes(shoeSize)) {
  //       this.form.controls.shoeSize.setValue(null);
  //     }
  //   }
  // }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isDerivedFrom(derivedFrom: string) {
    return this.member.measurements?.derivedFrom === derivedFrom;
  }

  hasShoesInLooks(): boolean {
    let hasShoes =
      this.member.memberLook?.styles.some((style) => {
        return style.styleType === StyleType.Shoes;
      }) ?? false;
    return hasShoes;
  }

  hasLocalStorageMeasurements(id: string): boolean {
    return localStorage.getItem(id) !== null;
  }

  setDerivedFrom(): void {
    const measurements: Measurements = this.member.measurements ?? {};
    if (measurements !== undefined) {
      measurements.derivedFrom = this.derivedFrom;
    }
    // if (!this.reviewMeasurements) {
    //   this.member.measurements = measurements;
    //   measurements.derivedFrom = this.derivedFrom;
    //   this.form.controls.derivedFrom.setValue(this.derivedFrom);
    //   this.updatedMember.emit(this.member);
    // }
  }

  has3DMeasurements(): boolean {
    // If there are measurements or 3d tool selected
    if (this.submitEnabled || this.form.status === 'VALID') {
      return true;
    }
    return false;
  }

  setSizeFieldWarn(shoeSizeFieldWarn: boolean) {
    this.shoeSizeFieldWarn = shoeSizeFieldWarn;
  }

  save3dLooks(): void {
    const shoeSize = this.form.controls.shoeSize.value;
    if (this.has3DMeasurements()) {
      this.member.measurements = {
        derivedFrom: this.derivedFrom,
        height: this.form.controls.height.value ?? undefined,
        weight: this.form.controls.weight.value ?? undefined,
        //coatSize: this.form.controls.coatSize.value ?? undefined,
        chest: this.form.controls.chest.value ?? undefined,
        overarm: this.form.controls.overarm.value ?? undefined,
        stomach: this.form.controls.stomach.value ?? undefined,
        waist: this.form.controls.waist.value ?? undefined,
        hip: this.form.controls.hip.value ?? undefined,
        outseam: this.form.controls.outseam.value ?? undefined,
        neck: this.form.controls.neck.value ?? undefined,
        sleeve: this.form.controls.sleeve.value ?? undefined,
        shoeSize: this.form.controls.shoeSize.value ?? undefined,
      };
      this.submitted = true;
      this.updatedMember.emit(this.member);
      this.removeMeasurementsFromStorage(this.member.id!);
    } else {
      this.setSizeFieldWarn(true);
    }
  }

  saveManualMeasurements(): void {
    if (this.form.status === 'VALID') {
      this.member.measurements = {
        derivedFrom: this.derivedFrom,
        height: this.form.controls.height.value ?? undefined,
        weight: this.form.controls.weight.value ?? undefined,
        //coatSize: this.form.controls.coatSize.value ?? undefined,
        chest: this.form.controls.chest.value ?? undefined,
        overarm: this.form.controls.overarm.value ?? undefined,
        stomach: this.form.controls.stomach.value ?? undefined,
        waist: this.form.controls.waist.value ?? undefined,
        hip: this.form.controls.hip.value ?? undefined,
        outseam: this.form.controls.outseam.value ?? undefined,
        neck: this.form.controls.neck.value ?? undefined,
        sleeve: this.form.controls.sleeve.value ?? undefined,
        shoeSize: this.form.controls.shoeSize.value ?? undefined,
      };
      this.submitted = true;
      this.updatedMember.emit(this.member);
      this.removeMeasurementsFromStorage(this.member.id!);
    } else {
      this.form.markAllAsTouched();
      this.measurementFormError =
        'PLEASE FILL OUT THE REQUIRED MEASUREMENT FIELDS.';
    }
  }

  removeMeasurementsFromStorage(id: string): void {
    localStorage.removeItem(id);
  }

  setLocalValue(id: string): void {
    const measurementsFromStorage = localStorage.getItem(id);
    if (measurementsFromStorage && this.form) {
      let measurements: Measurements = JSON.parse(measurementsFromStorage);
      if (this.member.id !== undefined) {
        this.form.setValue({
          memberId: this.member.id,
          derivedFrom: measurements?.derivedFrom ?? null,
          height: measurements?.height ?? null,
          weight: measurements?.weight ?? null,
          //coatSize: measurements?.coatSize ?? null,
          chest: measurements?.chest ?? null,
          overarm: measurements?.overarm ?? null,
          stomach: measurements?.stomach ?? null,
          waist: measurements?.waist ?? null,
          hip: measurements?.hip ?? null,
          outseam: measurements?.outseam ?? null,
          neck: measurements?.neck ?? null,
          sleeve: measurements?.sleeve ?? null,
          shoeSize: measurements?.shoeSize ?? null,
        });
      }
    }
    // this.removeMeasurementsFromStorage(id);
  }

  /**
   * Updates this.member.measurements and stores measurements in localStorage.
   * Properties for form fields that are not valid are stored as undefined in this.member.measurements (and localStorage).
   */
  onUpdate(): void {
    if (this.submitted === true) {
      return;
    }
    const {
      height,
      weight,
      chest,
      overarm,
      stomach,
      waist,
      hip,
      outseam,
      neck,
      sleeve,
      shoeSize,
    } = this.form.controls;

    this.member.measurements = {
      derivedFrom: this.derivedFrom,
      height: this.isValidMeasurement('height', height.value)
        ? height.value ?? undefined
        : undefined,
      weight: this.isValidMeasurement('weight', weight.value)
        ? weight.value ?? undefined
        : undefined,
      //coatSize: coatSize.valid ? coatSize.value ?? undefined : undefined,
      chest: this.isValidMeasurement('chest', chest.value)
        ? chest.value ?? undefined
        : undefined,
      overarm: this.isValidMeasurement('overarm', overarm.value)
        ? overarm.value ?? undefined
        : undefined,
      stomach: this.isValidMeasurement('stomach', stomach.value)
        ? stomach.value ?? undefined
        : undefined,
      waist: this.isValidMeasurement('waist', waist.value)
        ? waist.value ?? undefined
        : undefined,
      hip: this.isValidMeasurement('hip', hip.value)
        ? hip.value ?? undefined
        : undefined,
      outseam: this.isValidMeasurement('outseam', outseam.value)
        ? outseam.value ?? undefined
        : undefined,
      neck: this.isValidMeasurement('neck', neck.value)
        ? neck.value ?? undefined
        : undefined,
      sleeve: this.isValidMeasurement('sleeve', sleeve.value)
        ? sleeve.value ?? undefined
        : undefined,
      shoeSize: this.isValidMeasurement('shoeSize', shoeSize.value)
        ? shoeSize.value ?? undefined
        : undefined,
    };

    this.storeMeasurements(this.member?.id!, this.member.measurements);
    // this.updatedMember.emit(this.member);
  }

  isValidMeasurement(
    controlName: keyof typeof Ranges,
    value: string | number | null | undefined
  ) {
    if (value === null || value === undefined) return false;
    const range = Ranges[controlName as keyof typeof Ranges];
    const isValid = range.includes(value);
    return isValid;
  }

  showShoeSizeWarning(): boolean {
    return (
      this.shoeSizeFieldWarn &&
      this.hasShoesInLooks() &&
      this.form.controls.shoeSize.value === null
    );
  }

  validManualMeasurements(): boolean {
    return this.form.valid;
  }

  storeMeasurements(id: string, measurements: Measurements): void {
    localStorage.setItem(id, JSON.stringify(measurements));
  }
}
