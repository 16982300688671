@if (center) {
  <google-map
    width="100%"
    height="100%"
    [mapId]="googleMapId"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    (idle)="idle()"
    (centerChanged)="centerChange()"
    (zoomChanged)="onZoomChange()"
  >
    <ng-container *ngFor="let store of cachedStoreMarkers(); index as i">
      <map-advanced-marker
        #mapMarker="mapAdvancedMarker"
        [position]="store.marker.position!"
        [title]="store.marker.title!"
        (mapClick)="openInfo(mapMarker, store.store)"
        alt="test"
      >
      </map-advanced-marker>
      <map-info-window
        #infoWindow="mapInfoWindow"
        (closeclick)="onCloseClick()"
        (domready)="onDomReady()"
      >
        <div class="modal-main">
          <div class="info-window-header">
            <div class="close-modal">
              <button
                mat-icon-button
                class="close-button"
                (click)="closeInfo()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div class="header-text-container">
              <h3 *ngIf="activeStore()?.aname">{{ activeStore()?.aname }}</h3>
            </div>
          </div>

          <div class="address-info">
            <div>
              {{ activeStore()?.address1 }} {{ activeStore()?.address2 }}
            </div>
            <div>
              {{ activeStore()?.city }}, {{ activeStore()?.state | uppercase }}
              {{ activeStore()?.zipCode }}
            </div>
            <div *ngIf="activeStore()?.phone">{{ activeStore()?.phone }}</div>
          </div>
          <hr />
          <div *ngIf="chooseStore" class="store-details-button">
            <button
              mat-flat-button
              color="primary"
              target="_blank"
              [disabled]="activeStore()?.actnum === storeInEvent()?.actnum"
              *ngIf="!isSaving"
              (click)="selectStoreDetails(activeStore(), i)"
            >
              {{
                activeStore()?.actnum === storeInEvent()?.actnum
                  ? "STORE SELECTED"
                  : "SELECT STORE"
              }}
            </button>
            <div class="spinner-container">
              <mat-spinner *ngIf="isSaving" diameter="30"></mat-spinner>
            </div>
            <button
              mat-stroked-button
              color="accent"
              (click)="onGetDirectionsClick()"
              class="gtag-store-directions"
            >
              DIRECTIONS
            </button>
            <button
              *ngIf="activeStore()?.website"
              mat-stroked-button
              color="accent"
              (click)="openWebsite(activeStore()?.website)"
            >
              WEBSITE
            </button>
          </div>
          <div *ngIf="!chooseStore" class="store-details-button">
            <button
              mat-flat-button
              color="primary"
              target="_blank"
              (click)="
                openStoreDetails(activeStore()?.actnum, activeStore()?.aname)
              "
            >
              Store Info
            </button>

            <button
              mat-stroked-button
              color="accent"
              (click)="onGetDirectionsClick()"
              class="gtag-store-directions"
            >
              Directions
            </button>
            <button
              *ngIf="activeStore()?.website"
              mat-stroked-button
              color="accent"
              (click)="openWebsite(activeStore()?.website)"
            >
              WEBSITE
            </button>
          </div>
        </div>
        <hr />
        <div class="store-hours-container">
          <app-store-hours [store]="activeStore()" justifyContent="center">
          </app-store-hours>
        </div>
      </map-info-window>
    </ng-container>
  </google-map>
}
