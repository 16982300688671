import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Auth, authState } from '@angular/fire/auth';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '@jfw-library/ecommerce/core';
import {
  DealerEventDetailsFormControls,
  DealerEventDetailsFormSchema,
  DealerOrganizerDetailsFormControls,
  DealerOrganizerDetailsFormSchema,
  EVENT_DATE_TOO_SOON_MESSAGE,
  RequiredAdminSchema,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import {
  getMaxDateForEvent,
  getMinDateForOnlineEvent,
  getMinEventDateToday,
  getNameFromFirstAndLastName,
  getOrganizerFromEvent
} from 'business-logic';
import {
  DealerInfo,
  DealerUserEvent,
  Event,
  EventAdmin,
  EventAdminNoId,
  EventNameSchema,
  EventType,
  JFWNewEmailSchema,
} from 'common-types';
import { Subscription, catchError, firstValueFrom, map, of, switchMap } from 'rxjs';
import { DealerEventService } from '../../services/dealer-event/dealer-event.service';
import { EcommerceSharedModuleEventService } from '../../services/event/shared/ecommerce-shared-module-event.service';

const Dealer_Portal_Event_Type_Options = [
  EventType.Wedding,
  EventType.Quinceañera,
  EventType.Prom,
  EventType.SpecialEvent,
];
type DealerPortalEventTypeOptions =
  (typeof Dealer_Portal_Event_Type_Options)[number];

@Component({
  selector: 'app-dealer-event-details',
  templateUrl: './dealer-event-details.component.html',
  styleUrls: ['./dealer-event-details.component.scss'],
})
export class DealerEventDetailsComponent implements OnInit {
  private auth: Auth = inject(Auth);
  user = toSignal(authState(this.auth), { initialValue: null });


  /**Most validation for all fields is handled by the zod schema via the zodFormGroupValidator */
  eventDetailsForm = new FormGroup<DealerEventDetailsFormControls>(
    {
      eventName: new FormControl('', {
        nonNullable: true,
      }),
      eventType: new FormControl('', {
        nonNullable: true,
      }),
      eventDate: new FormControl(0, {
        nonNullable: true,
      }),
    },
    {
      validators: [
        ZodFormUtilities.zodFormGroupValidator(DealerEventDetailsFormSchema),
      ],
    }
  );

  /**Most validation for all fields is handled by the zod schema via the zodFormGroupValidator */
  organizerDetailsForm = new FormGroup<DealerOrganizerDetailsFormControls>({
    organizerFirstName: new FormControl('', {
      nonNullable: true,
    }),
    organizerLastName: new FormControl('', {
      nonNullable: true,
    }),
    organizerEmail: new FormControl('', {
      nonNullable: true,
      updateOn: 'blur',
      validators: [
        this.organizerEmailValidator(),
      ],
    }),
    organizerPhone: new FormControl('', {
      nonNullable: true,
    }),
  },
    {
      validators: [
        ZodFormUtilities.zodFormGroupValidator(DealerOrganizerDetailsFormSchema),
      ],
    }
  );


  dealerUserEvent: DealerUserEvent | undefined;

  /** True when editing an event.  False when creating an event. */
  editMode = toSignal(this.route.url.pipe(map(url => {
    const isEditMode = url[0].path === 'details';
    console.log('editMode', isEditMode);
    return isEditMode
  })), { initialValue: false });
  event = signal<Event | undefined>(undefined);
  eventDate = computed(() => this.event()?.eventDate ?? 0);
  organizer = computed(() => getOrganizerFromEvent(this.event()));
  organizerPhone = computed(() => this.organizer()?.phone ?? '');

  subscription = new Subscription();

  eventDetailsFormChanges = toSignal(this.eventDetailsForm.valueChanges, { initialValue: null });
  organizerFormChanges = toSignal(this.organizerDetailsForm.valueChanges, { initialValue: null });
  showEventDetailsFormWarning = computed(() => {
    this.eventDetailsFormChanges();
    console.log('form:', this.eventDetailsForm);
    const { eventName, eventType, eventDate } = this.eventDetailsForm.controls;
    if (this.eventDetailsForm.invalid) {
      if (eventName.invalid && eventName.dirty) {
        return eventName.errors?.schemaFieldErrors.some((err: string) => err.includes('required')) ? 'required' : 'other';
      }
      if (eventType.invalid && eventType.dirty) {
        return eventType.errors?.schemaFieldErrors.some((err: string) => err.includes('required')) ? 'required' : 'other';
      }
      if (eventDate.invalid && eventDate.dirty) {
        console.log("eventDate not valid and dirty")
        return eventDate.errors?.schemaFieldErrors.some((err: string) => err.includes('required')) ? 'required' : 'other';
      }
      return false;
    }
    return false;
  });

  /** Reacts to value changes on the organizer form.
   * Note: Will not fire on touched changes, only value changes,
   * so touching the field without changing the value will not trigger this signal.
   * This would require adding additional event listeners to each field.
   */
  showOrganizerFormWarning = computed(() => {
    this.organizerFormChanges();
    console.log('organizer form:', this.organizerDetailsForm);
    const {
      organizerFirstName,
      organizerLastName,
      organizerPhone,
      organizerEmail,
    } = this.organizerDetailsForm.controls;
    if (
      (organizerFirstName.invalid && organizerFirstName.touched) ||
      (organizerLastName.invalid && organizerLastName.touched) ||
      (organizerPhone.invalid && organizerPhone.touched) ||
      (organizerEmail.invalid && organizerEmail.touched)
    ) {
      return organizerEmail.hasError('organizerEmailMatch') ? 'email' : 'required';
    }
    return '';
  });
  showEventDateWarning = computed(() => {
    const eventDateValue = this.eventDetailsFormChanges()?.eventDate
    const { eventDate } = this.eventDetailsForm.controls;
    const isInvalid = eventDateValue !== 0 && eventDate.errors?.schemaFieldErrors.some((error: string) => error === EVENT_DATE_TOO_SOON_MESSAGE);
    const showEventDateWarning = this.editMode()
      ? isInvalid
      : isInvalid && eventDate.dirty;

    console.log('eventDate.value', eventDateValue, 'eventDate.errors', eventDate.errors, 'showEventDateWarning', showEventDateWarning);
    return showEventDateWarning;
  });

  eventDetailsFormDisabled = computed(() => {
    this.eventDetailsFormChanges();
    return this.eventDetailsForm.disabled;
  });

  organizerFormDisabled = computed(() => {
    this.organizerFormChanges();
    return this.organizerDetailsForm.disabled;
  });

  /** the date form is not affected by form.disable(), so need to manually disable it through its input */
  dateDisabled = computed(() => {
    return this.eventDetailsFormDisabled() ? { disabled: true, emitEvent: false } : false;
  });

  phoneDisabled = computed(() => {
    return this.organizerFormDisabled() ? { disabled: true, emitEvent: false } : false;
  });

  maxDate = signal<Date>(getMaxDateForEvent());
  minDate = signal<Date>(getMinEventDateToday());
  minDateForEvent = signal<Date>(getMinDateForOnlineEvent());

  readonly eventNameMaxLength = EventNameSchema.maxLength ?? 50;
  readonly organizerNameMaxLength =
    RequiredAdminSchema.shape.firstName.maxLength ?? 30;
  readonly emailMaxLength = JFWNewEmailSchema.maxLength ?? 50;
  eventTypeOptions = Dealer_Portal_Event_Type_Options;

  isAccepted = signal(false);
  isSaving = signal<boolean>(false);
  loading = computed(() => this.editMode() ? this.event() === undefined : false);

  creatingEvent = signal(false);

  nextButtonDisabled = computed(() => {
    this.eventDetailsFormChanges();
    this.organizerFormChanges();
    return this.eventDetailsForm.invalid || this.organizerDetailsForm.invalid
  });


  constructor(
    private eventService: EventService,
    private dealerEventService: DealerEventService,
    private route: ActivatedRoute,
    private ecomSharedModuleService: EcommerceSharedModuleEventService
  ) { }

  getFirstSchemaFieldError = ZodFormUtilities.getFirstSchemaFieldErrorMessage;

  ngOnInit(): void {


    // const route = this.route.snapshot.url[0].path;
    // if (route === 'details') {
    //   console.log('Edit mode enabled');
    //   this.editMode.set(true);
    this.subscription.add(
      this.eventService.selectedEvent$
        .pipe(
          switchMap((event) => this.initializeEventDetails(event, this.editMode())),
          catchError((error) => {
            console.error('Error initializing event details page', error);
            alert('There is a problem with this event.');
            return of(undefined);
          })
        )
        .subscribe()
    );
    // } else {
    //   console.log('Create mode enabled');
    //   this.loading.set(false);
    // }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async initializeEventDetails(event: Event | undefined, editMode: boolean) {
    /* this is mostly to prevent the form from becoming re-enabled after the event has been created
      because the selectedEvent$ will change and cause this initialization to fire.
    */
    if (this.creatingEvent()) {
      console.log('Create Event in progress.  Not initializing event details.');
      return;
    }
    console.log('Initializing event details');
    this.eventDetailsForm.disable({ emitEvent: false });
    this.organizerDetailsForm.disable({ emitEvent: false });

    this.maxDate.set(getMaxDateForEvent());
    this.minDate.set(getMinEventDateToday());
    this.minDateForEvent.set(getMinDateForOnlineEvent());


    if (editMode && event) {

      try {
        const dealerUserEvent =
          await this.dealerEventService.getDealerUserEventById(event.id);
        this.dealerUserEvent = dealerUserEvent;
      } catch (error) {
        console.error('Error getting dealerUserEvent', error);
        alert('There was an error getting your event.  Please try again.');
        return;
      }

      console.log('Initializing Form');

      if (!this.isDealerPortalEventTypeOptions(event.eventType)) {
        console.error(
          `The event type ${event.eventType} is not supported in the Dealer Portal.`
        );
        alert(
          'This event type is not supported in the Dealer Portal.  Please select a different event type.'
        );
        throw new Error(`Unsupported event type: ${event.eventType}`);
      }

      const organizer = getOrganizerFromEvent(event);
      const firstName = organizer?.firstName;
      const lastName = organizer?.lastName;

      this.eventDetailsForm.patchValue({
        eventName: event.eventName,
        eventType: event.eventType,
        eventDate: event.eventDate,
      }, { emitEvent: false });

      this.organizerDetailsForm.patchValue({
        organizerFirstName: firstName ?? '',
        organizerLastName: lastName ?? '',
        organizerEmail: organizer?.email ?? '',
        organizerPhone: organizer?.phone ?? '',
      }, { emitEvent: false });

      const { acceptedDate } = this.dealerUserEvent;

      const isAccepted = acceptedDate !== undefined && acceptedDate !== 0;
      this.isAccepted.set(isAccepted);

      if (isAccepted) {
        console.log('Event is accepted or too soon to make changes.  Forms will remain disabled.');
      } else {
        console.log('Event is not accepted.  Enabling forms.');
        this.eventDetailsForm.enable({ emitEvent: false });
        this.organizerDetailsForm.enable({ emitEvent: false });
      }

      this.event.set(event);

    } else {
      console.log('Initializing forms for new event');
      this.event.set(undefined);
      this.eventDetailsForm.reset();
      this.organizerDetailsForm.reset();
      this.eventDetailsForm.enable({ emitEvent: false });
      this.organizerDetailsForm.enable({ emitEvent: false });
    }



  }

  /** Dealer Portal currently only supports Weddings and Quinceañeras.
   * This function checks if the event type is one of those supported types.
   * If not, it will throw an error.
   * This function is used to validate the event type before initializing the form.
   */
  private isDealerPortalEventTypeOptions(
    value: EventType
  ): value is DealerPortalEventTypeOptions {
    return this.eventTypeOptions.some((type) => type === value);
  }


  // addChildForm<key extends keyof DealerEventDetailsFormControls>(
  //   name: key,
  //   // group: Exclude<RegisterNewEventFormControls[key], undefined>
  //   control: AbstractControl<any, any>
  // ) {
  //   this.form.setControl(name, control as any);
  // }

  organizerEmailValidator(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      if (email === this.user()?.email) {
        return { organizerEmailMatch: true };
      }

      return null;
    };
  }

  setDate(eventDate: number | undefined): void {
    console.log("Setting event date", eventDate);
    if (eventDate === undefined) {
      this.eventDetailsForm.controls.eventDate.setValue(0);
    } else {
      this.eventDetailsForm.controls.eventDate.setValue(eventDate);
      this.eventDetailsForm.controls.eventDate.markAsDirty();
    }
  }

  setPhone(organizerPhone: string | undefined): void {
    console.log("Setting organizer phone", organizerPhone);
    if (organizerPhone !== undefined) {
      this.organizerDetailsForm.controls.organizerPhone.setValue(organizerPhone);
      this.organizerDetailsForm.controls.organizerPhone.markAsDirty();
    }
  }

  onSubmit(): void {
    if (this.editMode()) {
      if (this.dealerUserEvent && this.event()) {
        this.updateEvent();
      }
    } else {
      this.createEvent();
    }
  }

  /** Checks to see if there are any changes to name, date, or type.  Then calls the event api, which updates the event details for the event and related dealerUserEvents */
  private async updateEventDetails() {
    if (this.eventDetailsForm.invalid || this.showEventDateWarning()) {
      return;
    }
    const event = this.event();
    if (event === undefined) {
      console.error('Event is undefined in updateEventDetails');
      return;
    }

    const { eventName, eventType, eventDate } = this.eventDetailsForm.value;
    // const eventDate = this.eventDate;

    // build the update object
    let eventDetailsUpdates: Parameters<
      typeof this.ecomSharedModuleService.updateEventDetails
    >[1] = {};

    if (eventName !== undefined && eventName.trim() !== event.eventName) {
      console.log('Event name changed');
      eventDetailsUpdates.eventName = eventName.trim();
    }
    if (
      eventType !== undefined &&
      eventType !== event.eventType &&
      eventType !== ''
    ) {
      console.log('Event type changed');
      eventDetailsUpdates.eventType = eventType;
    }
    if (eventDate !== event.eventDate) {
      console.log('Event date changed');
      eventDetailsUpdates.eventDate = eventDate;
    }

    if (Object.keys(eventDetailsUpdates).length === 0) {
      console.log('No changes to event details');
      return;
    }

    console.log('Saving with updateEventDetails');
    this.isSaving.set(true);
    return this.ecomSharedModuleService.updateEventDetails(
      event.id,
      eventDetailsUpdates
    );
  }

  /** Checks to see if there are any changes to organizer details, then calls the event api to update organizerInfo */
  private async updateOrganizerInfo() {
    if (this.organizerDetailsForm.invalid) {
      return;
    }
    const event = this.event();
    if (event === undefined) {
      console.error('Event is undefined in updateOrganizerInfo');
      return;
    }


    const firstName = this.organizerDetailsForm.controls.organizerFirstName.value.trim();
    const lastName = this.organizerDetailsForm.controls.organizerLastName.value.trim();
    const email = this.organizerDetailsForm.controls.organizerEmail.value.trim();
    const name = getNameFromFirstAndLastName(firstName, lastName);
    const phone = this.organizerDetailsForm.controls.organizerPhone?.value;

    const currentAdmin = event.admins[0];
    const updatedAdmin: EventAdmin = {
      id: currentAdmin.id,
      name,
      role: '',
      firstName,
      lastName,
      email,
      phone,
      isOrganizer: true,
    };
    let needsUpdate = false;
    for (const key in updatedAdmin) {
      if (
        updatedAdmin[key as keyof EventAdmin] !==
        currentAdmin[key as keyof EventAdmin]
      ) {
        needsUpdate = true;
        break;
      }
    }

    if (!needsUpdate) {
      console.log('No changes to organizer info');
      return;
    }

    console.log('Updating organizer info');

    /// see note below for why this check is necessary.
    if (currentAdmin.id === '') {
      const organizers = event.admins.filter((admin) => admin.isOrganizer);
      const numOrganizers = organizers.length;

      if (numOrganizers !== 1) {
        // this shouldn't really happen because there should always only be one admin with isOrganizer set to true.
        throw new Error(
          'This organizer cannot be updated.  This admin has no id and there is not exactly one organizer in the admins array.'
        );
      }
    }

    /// There are events where the organizer has an id of empty string.
    /// The api will update an organizer with no id if we pass 'organizer' as the adminId,
    /// but only if there is exactly one admin with isOrganizer set to true and that admin's id is the empty string.
    const adminId = currentAdmin.id === '' ? 'organizer' : currentAdmin.id;

    return this.ecomSharedModuleService.updateOrganizer(
      event.id,
      adminId,
      updatedAdmin
    );
  }

  /** Handles saves for both the Event Details and the Organizer Details. */
  async updateEvent() {
    const user = this.user();
    const event = this.event();

    if (user && event) {
      this.eventDetailsForm.disable();
      this.organizerDetailsForm.disable();
      this.isSaving.set(true);
      try {
        // Either or both of these functions can call the event api, so we need to await both of them,
        // Then we need to check the results to see if we need to update the selected event
        const updateEventAfterOrganizerSave = (await this.updateOrganizerInfo())
          ?.updatedEvent;
        const updatedEventAfterEventDetailsSave = (
          await this.updateEventDetails()
        )?.updatedEvent;
        if (
          updateEventAfterOrganizerSave &&
          !updatedEventAfterEventDetailsSave
        ) {
          console.log(
            'Setting selected event with updated event after only saving organizer details.'
          );
          this.eventService.setSelectedEventWithEvent(
            updateEventAfterOrganizerSave,
            'DealerEventDetailsComponent -- updateEvent()'
          );
        } else if (
          updatedEventAfterEventDetailsSave &&
          !updateEventAfterOrganizerSave
        ) {
          console.log(
            'Setting selected event with updated event after only saving event details.'
          );
          this.eventService.setSelectedEventWithEvent(
            updatedEventAfterEventDetailsSave,
            'DealerEventDetailsComponent -- updateEvent()'
          );
        } else if (
          updateEventAfterOrganizerSave &&
          updatedEventAfterEventDetailsSave
        ) {
          console.log(
            'Setting selected event with updated event after both saves (organizer details and event details)'
          );
          this.eventService.setSelectedEventWithEvent(
            updatedEventAfterEventDetailsSave,
            'DealerEventDetailsComponent -- updateEvent()'
          );
        } else {
          // This should never happen because both functions should return something or throw an error
          console.log(
            'No changes to event or organizer details.  No need to update selected event.'
          );
        }
      } catch (error) {
        console.error('Error updating event details', error);
        alert(
          'Sorry, there was an error saving your event details.  Please try again or contact Customer Service.'
        );
        // Recover by reloading the event
        try {
          console.log('Reloading event after error');
          this.event.set(undefined);
          const event = await this.eventService.getSelectedEvent(true, 'DealerEventDetailsComponent -- updateEvent()');
          console.log("Event successfully reloaded.  Re-initializing event details...");
          await this.initializeEventDetails(event, this.editMode());
          console.log('Event details re-initialized.  Re-enabling form.');
          this.isSaving.set(false);
          return;
        } catch (error) {
          // If we can't reload the event, reload the page
          console.error('Error reloading event', error);
          alert('The page will reload now.');
          window.location.reload();
          return;
        }
      }
    }

    const navigated = await this.eventService.routeToLastStep();
    if (!navigated) {
      console.error('There was a problem navigating to the next step.');
      alert('There was a problem navigating to the next step. The page will reload now.');
      window.location.reload();
    }

  }

  async createEvent(): Promise<void> {
    const user = this.user();
    if (!user) {
      console.error('User not logged in');
      return;
    }

    if (
      this.eventDetailsForm.invalid ||
      this.organizerDetailsForm.invalid ||
      this.showEventDateWarning() ||
      this.eventDetailsForm.controls.eventType.value === ''
    ) return;

    this.eventDetailsForm.disable();
    this.organizerDetailsForm.disable();
    this.isSaving.set(true);


    const firstName = this.organizerDetailsForm.controls.organizerFirstName.value.trim();
    const lastName = this.organizerDetailsForm.controls.organizerLastName.value.trim();
    const email = this.organizerDetailsForm.controls.organizerEmail.value.trim();
    const name = getNameFromFirstAndLastName(firstName, lastName);
    const phone = this.organizerDetailsForm.controls.organizerPhone?.value;
    const eventName = this.eventDetailsForm.controls.eventName.value;
    const eventType = this.eventDetailsForm.controls.eventType.value;
    const eventDate = this.eventDetailsForm.controls.eventDate?.value;

    const organizer: EventAdminNoId = {
      // id is generated in api
      firstName,
      lastName,
      name,
      phone,
      email,
      isOrganizer: true,
      role: '',
    };

    let dealerId: string;

    try {
      const dealerIds = await firstValueFrom(this.dealerEventService
        .getDealerIdsByUserId(user.uid));

      if (dealerIds.length === 0) {
        throw new Error('User does not have a dealerId');
      }
      dealerId = dealerIds[0]; // for now always use the first dealerId
    } catch (error) {
      console.error('Error getting dealerId', error);
      alert('There was an error creating your event');
      return;
    }

    const createdByDealer = {
      userId: user.uid,
      name: user.displayName ? user.displayName : '',
      email: user.email ? user.email : '',
    };

    const dealerInfo: DealerInfo = {
      dealerId: dealerId,
      createdByDealer: createdByDealer,
    };

    const event: Event = {
      id: '',
      dealerId: dealerId,
      dealerInfo: dealerInfo,
      eventName,
      eventType,
      eventDate,
      looks: [],
      memberIds: [],
      members: [],
      admins: [], // api adds the organizer as admin
      // admins: [admin],
    };

    try {
      this.creatingEvent.set(true);
      const newEvent = await this.dealerEventService.createEvent(
        event,
        organizer
      );
      console.log('Event created', newEvent);
      /// leave forms disabled to allow navigation to occur (happens in dealerEventService.createEvent )
      // this.eventDetailsForm.enable();
      // this.organizerDetailsForm.enable();
      return;
    } catch (error) {
      console.error('Error creating event', error);
      alert('There was an error creating your event');
      this.isSaving.set(false);
      this.creatingEvent.set(false);
      this.eventDetailsForm.enable();
      this.organizerDetailsForm.enable();
      return;
    }
  }
}
