<div class="modal-main">
  <div class="close-modal">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-header-container">
    <h2 class="store-name">{{ store!.aname }}</h2>
  </div>
  <div class="address-info">
    <div>{{ store!.address1 }} {{ store!.address2 }}</div>
    <div>
      {{ store!.city }}, {{ store!.state | uppercase }} {{ store!.zipCode }}
    </div>
    <div *ngIf="store!.phone">{{ store!.phone }}</div>
  </div>
  <hr />
  <div class="store-details-button">
    <button
      *ngIf="canSelectStore() && !savingStore"
      mat-flat-button
      color="primary"
      (click)="selectStore(store!)"
      class="select-store-nav-container"
      data-cy="select-store"
    >
      SELECT STORE
    </button>
    <div class="spinner-container">
      <mat-spinner *ngIf="savingStore" diameter="30"></mat-spinner>
    </div>

    <button
      class="gtag-store-directions"
      mat-stroked-button
      color="accent"
      (click)="onGetDirectionsClick()"
    >
      DIRECTIONS
    </button>
    <button
      class="call-button"
      *ngIf="store!.phone"
      mat-stroked-button
      color="accent"
      (click)="openDialer(store!.phone)"
    >
      CALL
    </button>
    <button
      *ngIf="store!.website"
      mat-stroked-button
      color="accent"
      (click)="openWebsite(store!.website)"
    >
      WEBSITE
    </button>
  </div>
  <hr />
  <div class="store-hours-container">
    <app-store-hours [store]="store" justifyContent="center"> </app-store-hours>
  </div>
</div>
