import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EcommerceMainEnvironment } from 'common-types';
@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(
    @Inject(DOCUMENT) private dom: any,
    @Inject('environment')
    private readonly environment: EcommerceMainEnvironment,
  ) {}

  setCanonicalURL(path?: string) {
    /**
     * CANONICAL
     * Documentation: https://developers.google.com/search/docs/crawling-indexing/consolidate-duplicate-urls
     *
     * Purpose of this function is to set the urls we want to be the main url that appears on search engines.
     * If there are multiple sites IE: www.example-1.com, www.example-2.com we want only one of these to be canonical.
     * Below we are setting everything to be canonical except in production we want:
     *  - https://jimsformalwear.com to be the main url even though we have multiple domains
     **/

    let canonicalUrl = `${this.environment.canonicalUrl}${path ?? this.dom.location.pathname}`;

    let currentCanon: HTMLLinkElement = this.dom.querySelector(
      "link[rel='canonical']",
    );

    if (this.environment.envVersion === 'prod') {
      canonicalUrl = `${this.environment.ecom_prefix_for_email_and_sms_communication}${this.dom.location.pathname}`;
    }

    // If allowIndexing is false we will never have canonicalUrl break here
    if (this.environment.allowIndexing === false) {
      this.removeCanonicalUrl();
      return;
    }

    if (currentCanon) {
      currentCanon.setAttribute('href', canonicalUrl);
    } else {
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', canonicalUrl);
    }
  }

  removeCanonicalUrl() {
    let currentCanon: HTMLLinkElement = this.dom.querySelector(
      "link[rel='canonical']",
    );
    if (currentCanon) {
      currentCanon.remove();
    }
  }
}
