import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  ECOM_DEALER_USER_API_SERVICES,
  ECOM_DEALER_USER_EVENT_API_SERVICES,
  ECOM_EVENT_API_SERVICES,
} from '@jfw-library/ecommerce/api-services';
import { EventService } from '@jfw-library/ecommerce/core';
import { getCurrentUnixTimestamp } from 'business-logic';
import {
  DealerEventNote,
  DealerUserEvent,
  DealerUserEventSearchQuery,
  DealerUserEventSearchType,
  Event,
  EventAdminNoId,
} from 'common-types';
import { Observable, Subscription, firstValueFrom, of } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DealerEventService implements OnDestroy {
  private auth: Auth = inject(Auth);
  private user: User | null = null;
  private subscription = new Subscription();

  private ecomEventApiService = inject(ECOM_EVENT_API_SERVICES.v7);
  private ecomDealerUserEventApiService = inject(
    ECOM_DEALER_USER_EVENT_API_SERVICES.v3
  );
  private ecomDealerUserApiService = inject(ECOM_DEALER_USER_API_SERVICES.v1);

  private dealerUserEvent: DealerUserEvent | null = null;

  constructor(private router: Router, private eventService: EventService) {
    this.subscription.add(
      authState(this.auth).subscribe({
        next: (user) => {
          this.user = user;
          if (user === null) {
            this.dealerUserEvent = null;
          }
        },
      })
    );
    console.log(
      'DealerEventService is using Event API version: ',
      this.ecomEventApiService.apiUrl
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async getEventById(eventId: string): Promise<Event> {
    return (
      await firstValueFrom(this.ecomEventApiService.getEventById(eventId))
    ).event;
  }

  public getDealerIdsByUserId(userId: string): Observable<string[]> {
    return this.ecomDealerUserApiService
      .getDealerIdsByUserId(userId)
      .pipe(map(({ dealerIds }) => dealerIds));
  }

  public async getDealerUserEventById(
    eventId: string,
    forceRefresh = false
  ): Promise<DealerUserEvent> {
    if (
      this.dealerUserEvent &&
      eventId === this.dealerUserEvent.eventId &&
      !forceRefresh
    ) {
      console.log('Returning cached dealerUserEvent');
      return this.dealerUserEvent;
    }
    console.log('Fetching dealerUserEvent from API');
    const dealerUserEvent = await firstValueFrom(
      this.ecomDealerUserEventApiService.getDealerUserEventByEventId(eventId)
    );
    this.dealerUserEvent = dealerUserEvent;
    return dealerUserEvent;
  }

  public searchDealerUserEvents(
    searchType: DealerUserEventSearchType,
    searchQuery: DealerUserEventSearchQuery
  ): Observable<DealerUserEvent[]> {
    switch (searchType) {
      case DealerUserEventSearchType.Past:
        return this.getPastEvents();
      case DealerUserEventSearchType.Future:
        return this.getFutureEvents();
      case DealerUserEventSearchType.NonTransferred:
        return this.getNonTransferredEvents();
      case DealerUserEventSearchType.NotAccepted:
        return this.getEventsNotAccepted();
      default:
        return this.getDealerUserEventsByQuery(searchQuery);
    }
  }

  private getPastEvents(): Observable<DealerUserEvent[]> {
    const searchQuery: DealerUserEventSearchQuery = {
      eventDateEnd: getCurrentUnixTimestamp(),
    };

    return this.getDealerUserEventsByQuery(searchQuery);
  }

  private getFutureEvents(): Observable<DealerUserEvent[]> {
    // two years
    const futureDate = Math.floor(
      new Date().setFullYear(new Date().getFullYear() + 2) / 1000
    );

    const searchQuery: DealerUserEventSearchQuery = {
      eventDateStart: getCurrentUnixTimestamp(),
      eventDateEnd: futureDate,
    };

    return this.getDealerUserEventsByQuery(searchQuery);
  }

  private getDealerUserEventsByQuery(
    searchQuery: DealerUserEventSearchQuery
  ): Observable<DealerUserEvent[]> {
    const eventDateStart = searchQuery.eventDateStart;
    const eventDateEnd = searchQuery.eventDateEnd;
    const eventName = searchQuery.eventName;

    let queryParam = new HttpParams();

    if (eventDateStart) {
      queryParam = queryParam.append('eventDateStart', eventDateStart);
    }

    if (eventDateEnd) {
      queryParam = queryParam.append('eventDateEnd', eventDateEnd);
    }

    if (eventName) {
      queryParam = queryParam.append('eventName', eventName);
    }

    return authState(this.auth).pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }
        return this.ecomDealerUserEventApiService
          .getDealerUserEventsByUserId(user.uid, queryParam)
          .pipe(
            map((events) =>
              events.sort(
                (a, b) =>
                  new Date(a.eventDate).getTime() -
                  new Date(b.eventDate).getTime()
              )
            )
          );
      }),
      share()
    );
  }

  private getNonTransferredEvents(): Observable<DealerUserEvent[]> {
    return authState(this.auth).pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }
        return this.ecomDealerUserEventApiService
          .getNonTransferredEventsByUserId(user.uid)
          .pipe(
            map((events) =>
              events.sort(
                (a, b) =>
                  new Date(a.eventDate).getTime() -
                  new Date(b.eventDate).getTime()
              )
            )
          );
      }),
      share()
    );
  }

  private getEventsNotAccepted(): Observable<DealerUserEvent[]> {
    return authState(this.auth).pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }
        return this.ecomDealerUserEventApiService
          .getEventsNotAcceptedByUserId(user.uid)
          .pipe(
            map((events) =>
              events.sort(
                (a, b) =>
                  new Date(a.eventDate).getTime() -
                  new Date(b.eventDate).getTime()
              )
            )
          );
      })
    );
  }

  public updateDealerUserEventById(
    eventId: string,
    dealerUserEvent: DealerUserEvent
  ): Promise<DealerUserEvent> {
    return firstValueFrom(
      this.ecomDealerUserEventApiService.updateDealerUserEventById(
        eventId,
        dealerUserEvent
      )
    );
  }

  public addDealerNote(
    dealerUserEvent: DealerUserEvent,
    noteText: string
  ): Promise<DealerUserEvent> {
    const newNote: DealerEventNote = {
      userId: this.user?.uid!,
      userDisplayName: this.user?.displayName!,
      date: getCurrentUnixTimestamp(),
      text: noteText,
    };

    dealerUserEvent.notes.unshift(newNote);

    return this.updateDealerUserEventById(
      dealerUserEvent.eventId,
      dealerUserEvent
    );
  }

  public markEventAsTransferred(eventId: string): Observable<DealerUserEvent> {
    if (!this.user) {
      throw new Error('User not authenticated');
    }

    return this.ecomDealerUserEventApiService.markEventAsTransferred(
      eventId,
      this.user
    );
  }

  public async setEventAccepted(eventId: string, acceptedDate: number) {
    const dealerUserEvent = await this.getDealerUserEventById(eventId);

    if (!dealerUserEvent.acceptedDate) {
      dealerUserEvent.acceptedDate = acceptedDate;
      await this.updateDealerUserEventById(eventId, dealerUserEvent);
    }
  }

  public async createEvent(
    event: Event,
    organizer: EventAdminNoId
  ): Promise<Event> {
    if (!this.user?.uid) {
      console.error('User not logged in');
      throw new Error('User not logged in');
    }

    event.createdByUserId = this.user?.uid;

    return firstValueFrom(this.ecomEventApiService.createEventFromDealerPortal({ event, organizer }).pipe(
      map(({ newEvent }) => this.eventService.setSelectedEventWithEvent(newEvent, 'DealerEventService - createEvent')),
      tap((newEvent) => {
        localStorage.removeItem('checkout_state')
        this.router.navigate(['/event', newEvent.id, 'add-looks']);
      }),
    ));
  }
}
