import { LayoutModule } from '@angular/cdk/layout';
import {
  CommonModule,
  IMAGE_CONFIG,
  IMAGE_LOADER,
  ImageLoaderConfig,
  NgOptimizedImage,
} from '@angular/common';
import { forwardRef, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CarouselModule } from '@jfw-library/ecommerce/common-components/carousel';
import {
  DateFormModule,
  PhoneFormModule,
} from '@jfw-library/shared/common-components/form-fields';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
} from 'common-types';
import { DealerEventDetailsComponent } from './components/dealer-event-details/dealer-event-details.component';
import { EventHeaderComponent } from './components/event-header/event-header.component';
import { EventModalComponent } from './components/event-modal/event-modal.component';
import { EventTimelineComponent } from './components/event-timeline/event-timeline.component';
import { FinalizeMeasurementsComponent } from './components/finalize-measurements/finalize-measurements.component';
import { LookMannequinAccessoriesComponent } from './components/look-mannequin-accessories/look-mannequin-accessories.component';
import { LookMannequinComponent } from './components/look-mannequin/look-mannequin.component';
import { MeasurementsToolComponent } from './components/measurements-tool/measurements-tool.component';
import { MemberMeasurementsComponent } from './components/member-measurements/member-measurements.component';
import { NewEventModalComponent } from './components/new-event-modal/new-event-modal.component';
import { StoreHoursComponent } from './components/store/store-hours/store-hours.component';
import { StoreInfoModalComponent } from './components/store/store-info-modal/store-info-modal.component';
import { StoreLocatorComponent } from './components/store/store-locator/store-locator.component';
import { StoreMapComponent } from './components/store/store-map/store-map.component';
import { ViewMeasurementsComponent } from './components/view-measurements/view-measurements.component';
import { MannequinImagePipe } from './pipes/mannequin-image/mannequin-image.pipe';
import { MarkdownPipe } from './pipes/markdown/markdown.pipe';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { AngularFireAuthService } from './services/angular-fire-auth/angular-fire-auth.service';
import { CanonicalService } from './services/canonical/canonical.service';
import { CartApiService } from './services/cart/cart-api/cart-api.service';
import { EcomCommunicationQueueService } from './services/communication-queue/communication-queue.service';
import { DealerEventService } from './services/dealer-event/dealer-event.service';
import { EcommerceSharedModuleEventService } from './services/event/shared/ecommerce-shared-module-event.service';
import { LookService } from './services/look/look.service';
import { MeasurementApiService } from './services/measurement/measurement-api.service';
import { MeasurementService } from './services/measurement/measurement.service';
import { OrderService } from './services/order/order.service';
import { StoreService } from './services/store/store.service';
import { TaxApiService } from './services/tax/tax-api/tax-api.service';
import { TaxService } from './services/tax/tax/tax.service';
import { UpdateUserAccountService } from './services/update-user-account/update-user-account.service';
import { ValidationService } from './services/validation/validation.service';
import { ImagekitioAngularModule } from 'imagekitio-angular';

// Define an InjectionToken for the environment

function imageLoaderFactory(
  environment: EcommerceMainEnvironment | DealerPortalEnvironment,
) {
  return (config: ImageLoaderConfig) => {
    if (config.src.startsWith('assets/')) {
      return `${config.src}`;
    } else if (config.src.includes('IMAGEKIT|')) {
      config.src = config.src.replace('IMAGEKIT|', '');
      let url = '';

      if (config.width !== undefined) {
        url = `${environment.imageKitUrl}/tr:w-${config.width}/${environment.imageKitBaseUrlDimensions}%2F${config.src}?alt=media`;
      }

      return url;
    } else {
      if (config.width === 288) {
        config.width = 384;
      }
      const url = `https://firebasestorage.googleapis.com/v0/b/jfw-omni-dev-image-cache/o/${config.width}x${
        config.width! * 1.5
      }%2F${config.src}?alt=media`;
      return url;
    }
  };
}

@NgModule({
  // Any components that will be imported into a TS file or added via
  // HTML selector (ex. app-phone-form) need to be added to exports array.
  // Sub-components or directives do not need to be exported.
  declarations: [
    DealerEventDetailsComponent,
    EventHeaderComponent,
    EventModalComponent,
    EventTimelineComponent,
    FinalizeMeasurementsComponent,
    LookMannequinAccessoriesComponent,
    LookMannequinComponent,
    MannequinImagePipe,
    MarkdownPipe,
    MeasurementsToolComponent,
    MemberMeasurementsComponent,
    NewEventModalComponent,
    PhonePipe,
    StoreHoursComponent,
    StoreInfoModalComponent,
    StoreLocatorComponent,
    StoreMapComponent,
    ViewMeasurementsComponent,
  ],

  // All modules imported into the Shared Module need to be added
  // to the exports array for the components to function properly.
  imports: [
    CarouselModule,
    CommonModule,
    DateFormModule,
    FormsModule,
    GoogleMapsModule,
    LayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    NgOptimizedImage,
    PhoneFormModule,
    ReactiveFormsModule,
    RouterModule,
    ImagekitioAngularModule.forRoot({
      urlEndpoint: 'https://ik.imagekit.io/5stua7lt4/',
      publicKey: '5stua7lt4',
    }),
  ],
  providers: [
    // {
    //   provide: IMAGE_CONFIG,
    //   useValue: {
    //     breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
    //   },
    // },
    // {
    //   provide: IMAGE_LOADER,
    //   useValue: (config: ImageLoaderConfig) => {
    //     const url = `https://firebasestorage.googleapis.com/v0/b/jfw-omni-dev-image-cache/o/${
    //       config.width
    //     }x${config.width! * 1.5}%2F${config.src}?alt=media`;
    //     return url;
    //   },
    // },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
      },
    },
    {
      provide: IMAGE_LOADER,
      useFactory: imageLoaderFactory,
      deps: [forwardRef(() => 'environment')],
    },
  ],
  exports: [
    // components
    DealerEventDetailsComponent,
    EventHeaderComponent,
    EventModalComponent,
    EventTimelineComponent,
    FinalizeMeasurementsComponent,
    LookMannequinAccessoriesComponent,
    LookMannequinComponent,
    MannequinImagePipe,
    MeasurementsToolComponent,
    MemberMeasurementsComponent,
    NewEventModalComponent,
    StoreHoursComponent,
    StoreInfoModalComponent,
    StoreLocatorComponent,
    StoreMapComponent,
    ViewMeasurementsComponent,

    // pipes
    MannequinImagePipe,
    MarkdownPipe,
    PhonePipe,

    // directives

    // dependencies
    CarouselModule,
    CommonModule,
    DateFormModule,
    FormsModule,
    GoogleMapsModule,
    LayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    NgOptimizedImage,
    PhoneFormModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class EcommerceSharedModule {
  public static forRoot(
    environment: EcommerceMainEnvironment | DealerPortalEnvironment,
  ): ModuleWithProviders<EcommerceSharedModule> {
    return {
      ngModule: EcommerceSharedModule,
      providers: [
        AngularFireAuthService,
        CartApiService,
        CanonicalService,
        DealerEventService,
        EcomCommunicationQueueService,
        EcommerceSharedModuleEventService,
        LookService,
        MeasurementApiService,
        MeasurementService,
        OrderService,
        StoreService,
        TaxApiService,
        TaxService,
        UpdateUserAccountService,
        ValidationService,
        {
          provide: 'environment',
          useValue: environment,
        },
      ],
    };
  }
}
