<div class="store-detail-hours" [ngClass]="setClassName()">
  <div class="store-days">
    <span>Monday</span>
    <span>Tuesday</span>
    <span>Wednesday</span>
    <span>Thursday</span>
    <span>Friday</span>
    <span>Saturday</span>
    <span>Sunday</span>
  </div>
  <div class="store-hours">
    <span>
      {{
        storeHoursDisplayType(store?.mon_type, store?.mon_from, store?.mon_to)
      }}
    </span>
    <span>
      {{
        storeHoursDisplayType(store?.tue_type, store?.tue_from, store?.tue_to)
      }}
    </span>
    <span>
      {{
        storeHoursDisplayType(store?.wed_type, store?.wed_from, store?.wed_to)
      }}
    </span>
    <span>
      {{
        storeHoursDisplayType(store?.thu_type, store?.thu_from, store?.thu_to)
      }}
    </span>
    <span>
      {{
        storeHoursDisplayType(store?.fri_type, store?.fri_from, store?.fri_to)
      }}
    </span>
    <span>
      {{
        storeHoursDisplayType(store?.sat_type, store?.sat_from, store?.sat_to)
      }}
    </span>
    <span>
      {{
        storeHoursDisplayType(store?.sun_type, store?.sun_from, store?.sun_to)
      }}
    </span>
  </div>
</div>
