import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  signal,
  ViewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GoogleMap, MapAnchorPoint, MapInfoWindow } from '@angular/google-maps';
import { Router } from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import {
  standardTime,
  storeHoursConverter,
  titleCaseStoreDetails,
} from 'business-logic';
import { DealerAccountElastic, Event, StoreMarker } from 'common-types';
import { firstValueFrom } from 'rxjs';

const DEFAULT_LAT = 38.63775492936005;
const DEFAULT_LNG = -90.2088415376939;

const MAX_STORES_TO_ADD_IN_SINGLE_MAP_MOVEMENT = 50;

@Component({
  selector: 'app-store-map',
  templateUrl: './store-map.component.html',
  styleUrls: ['./store-map.component.scss'],
})
export class StoreMapComponent implements OnInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private _event: Event | undefined;
  @Input()
  set event(ev: Event | undefined) {
    this._event = ev;
    const storeInEvent = ev?.inStoreInfo?.store;
    if (storeInEvent) {
      this.storeInEvent.set(storeInEvent);
    }
    if (storeInEvent) {
      this.setSelectedStoreCircle(storeInEvent);
    }
  };
  get event(): Event | undefined {
    return this._event;
  }
  storeInEvent = signal(this.event?.inStoreInfo?.store);
  @Input()
  set searchCoordinates(value: google.maps.LatLngBounds | undefined) {
    if (value) {
      this.map.fitBounds(value);
    }
  };
  @Input() center: google.maps.LatLng | undefined = this.isBrowser
    ? new google.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG,)
    : undefined;
  @Input() chooseStore: boolean = false;
  @Input() isSaving = false;
  @Output() storeList = new EventEmitter<{ storeMarkersInBox: Array<StoreMarker>, cachedStoreMarkers: Array<StoreMarker> }>();
  @Output() storeToFocus = new EventEmitter<DealerAccountElastic>();
  @Output() selectStore = new EventEmitter<DealerAccountElastic>();
  @Output() storeNameClicked = new EventEmitter<string>();


  zoom: number = 10;
  breakpointObserveXSmall = toSignal(this.breakpointObserver.observe(Breakpoints.XSmall));
  isMobile = computed(() => this.breakpointObserveXSmall()?.matches);
  options: google.maps.MapOptions = {
    disableDefaultUI: true,
    fullscreenControl: true,
    zoomControl: true,
  };
  currentMarkerPoint = signal<MapAnchorPoint | undefined>(undefined);
  cachedStoreMarkers = signal<Array<StoreMarker>>([]);
  activeStore = signal<DealerAccountElastic | undefined>(undefined);
  storesInBox = signal<Array<DealerAccountElastic> | undefined>(undefined);

  showMap = signal(true);
  googleMapId: string;
  storeMapper = new Map<string, boolean>();
  circle!: google.maps.Circle;
  // saveSelectingStoreIndex = signal<Array<boolean>>([]);
  private document = inject(DOCUMENT);

  constructor(
    // @Inject(DOCUMENT) private document: Document
    @Inject('environment') private environment: any,
    private search: SearchService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.googleMapId = this.environment.googleMapId;
  }

  ngOnInit(): void {
    // this.saveSelectingStoreIndex.set(new Array(this.stores.length).fill(false));
  }

  applyAreaTagAlt(): void {
    setTimeout(() => {
      var areaTags = this.document.querySelectorAll('area');
      areaTags.forEach(function (area) {
        area.alt = area.title.toLowerCase();
      });
    }, 2000);
  }

  private getInfoWindowOffsetByRoute(): number {
    const gmap = this.document.getElementsByTagName('google-map')[0];
    const infoWindow = this.document.getElementsByTagName('map-info-window')[0];
    const gmapHeight = gmap.getBoundingClientRect().height;
    const infoWindowHeight = infoWindow.getBoundingClientRect().height;
    const width = gmap.getBoundingClientRect().width;
    const offset = (gmapHeight / 2 * -1) + (infoWindowHeight / 2);
    console.log('gmap dimensions:', { height: gmapHeight, width, offset });
    const currentRoute = this.router.url;
    if (currentRoute.includes('choose-store')) {
      return offset;
    } else {
      return 0;
    }
  }

  private async generateMarkers() {
    if (!this.isBrowser) return;
    let center = this.map.getCenter();
    let bounds = this.map.getBounds();

    if (center == undefined) {
      // console.log('getting center from localstorage');
      center = JSON.parse(localStorage.getItem('map-center') || '[]');
    }

    if (bounds == undefined) {
      // console.log('getting bounds from localstorage');
      center = JSON.parse(localStorage.getItem('map-bounds') || '[]');
    }

    if (center !== undefined && bounds !== null) {
      await this.addMarkers(center, bounds);
      const mapElement = this.document.getElementById('gMap');
      if (mapElement) {
        if (this.showMap()) {
          mapElement.style.display = 'block';
        } else {
          mapElement.style.display = 'none';
        }
      }
    }
    if (this.currentMarkerPoint() && this.activeStore() && !this.isMobile()) {
      setTimeout(() => {
        if (this.infoWindow) {
          this.infoWindow.open(this.currentMarkerPoint());

          // let options: google.maps.InfoWindowOptions = {
          //   pixelOffset: new google.maps.Size(150, 10),
          // };
          // this.infoWindow.infoWindow?.setOptions(options);
        }
        this.currentMarkerPoint.set(undefined);

      });
    }
  }

  ngAfterViewInit() {
    this.generateMarkers();
  }

  idle(): void {
    console.log('idle');
    this.generateMarkers();
  }

  centerChange(): void {
    if (!this.currentMarkerPoint()) {
      this.closeInfo();
    }
  }

  private async addMarkers(center: google.maps.LatLng, bounds: google.maps.LatLngBounds) {
    // console.log("addMarkers...");
    localStorage.setItem('map-center', JSON.stringify(center));
    localStorage.setItem('map-bounds', JSON.stringify(bounds));

    this.clearExistingMarkers();
    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();
    const searchCriteria = {
      location: {
        top_left: {
          lat: northeast.lat(),
          lon: southwest.lng(),
        },
        bottom_right: {
          lat: southwest.lat(),
          lon: northeast.lng(),
        },
      },
    };

    // ELASTIC SEARCH
    const storesInBox: any = await firstValueFrom(this.search
      .getStoresByBoundingBox(center.lat(), center.lng(), searchCriteria));

    // console.log('stores from getStoresByBoundingBox:', storesInBox);
    const formattedStoresInBox = titleCaseStoreDetails(storesInBox);
    const storeMarkersInBox: StoreMarker[] = formattedStoresInBox.map((store) => {
      return {
        store: store,
        marker: this.createMarkerFromStore(store),
      };
    });

    // Only show the closest to center.
    let storesAdded = 1;

    const cachedStoreMarkers = this.cachedStoreMarkers();

    for (let store of formattedStoresInBox) {
      if (this.storeMapper.get(store.actnum) !== true) {
        cachedStoreMarkers.push({
          store: store,
          marker: this.createMarkerFromStore(store),
        });
        this.storeMapper.set(store.actnum, true);
        this.applyAreaTagAlt();
      }

      storesAdded++;
      if (storesAdded > MAX_STORES_TO_ADD_IN_SINGLE_MAP_MOVEMENT) {
        break;
      }
    }

    this.cachedStoreMarkers.set(cachedStoreMarkers);
    this.storesInBox.set(formattedStoresInBox);
    // For debugging only. It shows how much localStorage is being used
    //this.calculateLocalStorageSize();
    this.storeList.emit({ storeMarkersInBox, cachedStoreMarkers });

    // console.log('stores length:', this.cachedStoreMarkers().length);
    // console.log('storesInBox length:', this.storesInBox()?.length);

    if (!this.currentMarkerPoint()) {
      const eventStore = this.event?.inStoreInfo?.store;
      if (eventStore) {
        this.setCircle(eventStore, true);
      }
    }
  }

  setSelectedStoreCircle(storeInEvent: DealerAccountElastic | undefined) {
    // console.log("setSelectedStoreCircle");
    if (storeInEvent) {
      // console.log("setSelectedStoreCircle with store", storeInEvent);
      // const currentCenter = this.circle?.getCenter();
      // if (currentCenter?.lat() === storeInEvent.location.lat && currentCenter?.lng() === storeInEvent.location.lon) {
      //   // console.log("circle already set to the event's store");
      //   return;
      // }
      this.setCircle(storeInEvent, true);
    }
  }

  calculateLocalStorageSize() {
    var _lsTotal = 0,
      _xLen,
      _x;
    for (_x in localStorage) {
      if (!localStorage.hasOwnProperty(_x)) {
        continue;
      }
      _xLen = (localStorage[_x].length + _x.length) * 2;
      _lsTotal += _xLen;
      // console.log(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB');
    }
    // console.log('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB');
  }

  clearExistingMarkers() {
    const stores = this.cachedStoreMarkers().map((store) => {
      store.marker.map = null;
      return store;
    });
    this.cachedStoreMarkers.set(stores);

    // for (var i = 0; i < this.stores.length; i++) {
    //   this.stores[i].marker.map = null;
    // }
  }

  // createMarkerFromStore(store: Store): google.maps.Marker {
  //   const position: google.maps.LatLng = new google.maps.LatLng({
  //     lat: store.lat,
  //     lng: store.lon,
  //   });

  //   const markerOptions: google.maps.MarkerOptions = {
  //     position: position,
  //     title: store.aname,
  //     label: {
  //       text: store.aname,
  //       color: '#517aa3',
  //     },
  //     //https://developers.google.com/maps/documentation/javascript/reference/marker for optimized flag details
  //     // optimized: false,
  //   };
  //   return new google.maps.Marker(markerOptions);
  // }

  createMarkerFromStore(
    store: DealerAccountElastic,
  ): google.maps.marker.AdvancedMarkerElement {
    const position: google.maps.LatLng = new google.maps.LatLng({
      lat: store.location.lat,
      lng: store.location.lon,
    });
    const markerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
      position: position,
      title: store.aname,
      /*
      label: {
        text: store.aname,
        color: '#517aa3',
      },
      */
      //https://developers.google.com/maps/documentation/javascript/reference/marker for optimized flag details
      // optimized: false,
    };
    let element = new google.maps.marker.AdvancedMarkerElement(markerOptions);
    return element;
  }

  standardTime(time: string): string {
    return standardTime(time);
  }

  // titleCaseStoreDetails(stores: Array<Store>): Array<Store> {
  //   return titleCaseStoreDetails(stores);
  // }
  titleCaseStoreDetails(
    stores: Array<DealerAccountElastic>,
  ): Array<DealerAccountElastic> {
    return titleCaseStoreDetails(stores);
  }

  storeHoursConverter(
    fromTime: string | undefined,
    toTime: string | undefined,
  ): string {
    return storeHoursConverter(fromTime, toTime);
  }

  openInfo(markerElem: MapAnchorPoint, store: DealerAccountElastic) {
    // this.selectedStoreIndex.emit(storeIndex);
    this.storeNameClicked.emit(store.aname);
    this.currentMarkerPoint.set(markerElem);
    this.activeStore.set(store);
    this.storeToFocus.emit(store);
    if (!this.isMobile()) {
      this.infoWindow.open(markerElem);
      this.setCircle(store, false);

      // Find map dimensions in lat/lon.
      // var bounds = this.map.getBounds();
      // var mapWidth = 0;
      // var mapHeight = 0;
      // if (bounds) {
      //   var mapWidth =
      //     bounds?.getSouthWest().lng() - bounds.getNorthEast().lng();
      //   var mapHeight =
      //     bounds.getNorthEast().lat() - bounds.getSouthWest().lat();
      // }


      // Find the position of the infowindow wrt the store's marker.  ALso circle radius.
      // var iwLat = store.lat + mapWidth * 0.17;
      // var iwLon = store.lon + mapHeight * 0.4664;

      /* REMOVING THIS made the info window open in the right spot.
      var iwLat = store.location.lat + mapWidth * 0.17;
      var iwLon = store.location.lon + mapHeight * 0.4664;
      var circleWidth = mapHeight * 6000;

      this.infoWindow.infoWindow?.setPosition(
        new google.maps.LatLng(iwLat, iwLon),
      );

      let options: google.maps.InfoWindowOptions = {
        pixelOffset: new google.maps.Size(0, this.getInfoWindowOffsetByRoute()),
      };
      this.infoWindow.infoWindow?.setOptions(options);
      */

    }
  }

  closeInfo(): void {
    // console.log("closeInfo");
    if (this.infoWindow) {
      this.infoWindow.close();
    }
    const storeInEvent = this.storeInEvent();
    if (storeInEvent) {
      this.setSelectedStoreCircle(storeInEvent);
    } else this.removeCircle();
    this.activeStore.set(undefined);
    this.storeNameClicked.emit('');
  }

  setCircle(store: DealerAccountElastic, isStoreInEvent: boolean) {
    // console.log("setCircle");
    if (!this.map) {
      return
    };
    // console.log("setCircle with store", store);
    const bounds = this.map.getBounds();
    const mapHeight = bounds ? bounds.getNorthEast().lat() - bounds.getSouthWest().lat() : 0;
    const mapWidth = bounds ? bounds.getSouthWest().lng() - bounds.getNorthEast().lng() : 0;
    const circleWidth = mapHeight * 6000;

    this.circle?.setMap(null);
    this.circle = new google.maps.Circle({
      strokeWeight: isStoreInEvent ? 1 : 0,
      strokeColor: '#517aa3',
      fillColor: isStoreInEvent ? '#517aa3' : 'grey',
      fillOpacity: 0.6,
      map: this.map.googleMap,
      // center: new google.maps.LatLng(store.lat, store.lon),
      center: new google.maps.LatLng(store.location.lat, store.location.lon),
      radius: circleWidth,
    });

  }

  removeCircle() {
    console.log("removeCircle");
    this.circle?.setMap(null);
  }

  onZoomChange() {
    this.closeInfo();
  }

  onCloseClick() {
    this.removeCircle();
  }

  // This is fragile as it relies on finding the arrow on the info window using a class attached
  // to the arrow, which could change with a new version of the map library.
  onDomReady() {
    console.log('DomReady: finding and removing map arrow');
    var arrows = this.document.getElementsByClassName('gm-style-iw-tc');
    for (var i = 0; i < arrows.length; i++) {
      var arrow = arrows[i];
      console.log(`map arrow: ${arrow.outerHTML}`);
      arrow.remove();
    }
  }

  async selectStoreDetails(
    store: DealerAccountElastic | undefined,
    index: number,
  ): Promise<void> {
    if (this.event && store) {
      this.selectStore.emit(store);

      // this.saveSelectingStoreIndex[index] = true;
      // try {
      //   const {updatedEvent} = await this.storeService.setStoreForEvent(
      //     this.event,
      //     store
      //   );
      //   this.eventService.setSelectedEventWithEvent(updatedEvent);
      //   this.selectStore.emit({store, index});
      //   this.saveSelectingStoreIndex[index] = false;
      // } catch (error) {
      //   alert('Something went wrong saving store');
      //   this.saveSelectingStoreIndex[index] = false;
      // }
    }
  }

  onGetDirectionsClick(): void {
    // let coordinates = `${this.activeStore()?.lat},${this.activeStore()?.lon}`;
    let coordinates = `${this.activeStore()?.location.lat},${this.activeStore()?.location.lon}`;
    let url =
      'https://www.google.com/maps/dir/?api=1&destination=' + coordinates;
    window.open(url, '_blank');
  }

  openStoreDetails(
    storeId: string | undefined,
    name: string | undefined,
  ): void {
    let encodedName = '';
    if (name != undefined) {
      let hyphenatedName = name.replaceAll(' ', '-');
      encodedName = encodeURIComponent(hyphenatedName).replace(
        /[!'()~]/g,
        escape,
      );
    }

    console.log(`Encoded store name: ${encodedName}`);
    this.router.navigate([`/store-detail/${encodedName}-${storeId}`]);
  }

  openWebsite(website: string | undefined): void {
    let url = 'https://' + website;
    if (website) {
      window.open(url, '_blank');
    }
  }
}
