import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventService, ScrollService } from '@jfw-library/ecommerce/core';
import { UserEcomSettingsService } from '@jfw-library/shared/services';
import { DealerAccountElastic, Event, StoreMarker } from 'common-types';
import { Subscription } from 'rxjs';
import { StoreService } from '../../../services/store/store.service';
// import { StoreInfoModalComponent } from '../store-info-modal/store-info-modal.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { MapInfoWindow } from '@angular/google-maps';
import { StoreInfoModalComponent } from '../store-info-modal/store-info-modal.component';

const DEFAULT_LAT = 38.63775492936005;
const DEFAULT_LNG = -90.2088415376939;

@Component({
  selector: 'app-store-locator',
  templateUrl: './store-locator.component.html',
  styleUrls: ['./store-locator.component.scss'],
})
export class StoreLocatorComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  @ViewChild('mapSearchField') searchField!: ElementRef;
  @ViewChild('mapSearchFieldMobile') searchFieldMobile!: ElementRef;
  @ViewChild('storeListContainer') storeListContainer!: ElementRef;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @Output() storeSelected = new EventEmitter<void>();
  searchCoordinates = signal<google.maps.LatLngBounds | undefined>(undefined);
  _event = signal<Event | undefined>(undefined);

  @Input()
  set event(ev: Event | undefined) {
    this._event.set(ev);
    if (!ev) {
      return;
    }
    this.setCenter(ev, 'event Input');
    const storeInEvent = ev?.inStoreInfo?.store;
    this.storeInEvent.set(storeInEvent);
    this.selectedStoreName.set(storeInEvent?.aname ?? '');
  }

  storeInEvent = signal<NonNullable<Event['inStoreInfo']>['store']>(undefined);

  @Input() chooseStore: boolean = false;
  showMap = signal(true);
  zipcode: string | undefined;
  cachedStoreMarkers = signal<StoreMarker[]>([]);
  storeMarkersInBox = signal<StoreMarker[]>([]);
  storesToShow = computed(() => {
    const storeInEvent = this.storeInEvent();
    const storeToSelect = this.cachedStoreMarkers().find(
      (storeMarker) => storeMarker.store.aname === this.storeToHighlight(),
    );
    /// always include the selected store in the list (as the first item)
    const storesToShow: DealerAccountElastic[] = storeInEvent
      ? [storeInEvent]
      : [];
    /// if the selected store is not in the box, include it in the list (sometimes the scroll causes the selected store to be out of the box)
    if (storeToSelect && storeToSelect.store.actnum !== storeInEvent?.actnum) {
      storesToShow.push(storeToSelect.store);
    }
    this.storeMarkersInBox().forEach(({ store }) => {
      if (
        storeInEvent?.actnum === store.actnum ||
        storeToSelect?.store.actnum === store.actnum
      ) {
        return;
      }
      storesToShow.push(store);
    });

    return storesToShow;
  });
  selectedStoreName = signal('');
  storeToHighlight = signal('');
  breakpointObserveXSmall = toSignal(
    this.breakpointObserver.observe(Breakpoints.XSmall),
  );
  isMobile = computed(() => this.breakpointObserveXSmall()?.matches);
  center = signal<google.maps.LatLng | undefined>(
    this.isBrowser
      ? new google.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG)
      : undefined,
  );
  geocoder?: google.maps.Geocoder;
  subscription = new Subscription();
  reorderStores = signal(true);
  scrollSelectedStoreIntoView = signal(false);
  showScrollToTop = signal(false);
  saveSelectingStoreIndex = signal<boolean[]>([]);
  isSaving = computed(() => {
    const isSaving = this.saveSelectingStoreIndex()?.some((val) => val);
    // console.log('isSaving Store', isSaving);
    return isSaving;
  });
  isLoading = signal(true);
  private document: Document = inject(DOCUMENT);

  constructor(
    public dialog: MatDialog,
    private storeService: StoreService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private eventService: EventService,
    private breakpointObserver: BreakpointObserver,
    private scrollService: ScrollService,
    private userService: UserEcomSettingsService,
  ) {}

  ngOnInit(): void {
    // this.saveSelectingStoreIndex.set(new Array(this.cachedStoreMarkers().length).fill(false));
    if (!this._event()) {
      console.log('No Event, setting center');
      this.setCenter(undefined, 'ngAfterViewInit');
    } else {
      /// This is just to warm up the UserEcomSettings API for the next screen
      this.userService.setUserEcomSettings();
    }
  }

  ngAfterViewInit(): void {
    // this.geocoder = new google.maps.Geocoder();

    this.initializeSearchBox();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const storeListContainerHeight =
      this.storeListContainer.nativeElement.offsetHeight;
    if (
      storeListContainerHeight !== 0 &&
      window.pageYOffset > 0.5 * storeListContainerHeight
    ) {
      this.showScrollToTop.set(true);
    } else {
      this.showScrollToTop.set(false);
    }
  }

  scrollToTop(): void {
    this.scrollService.scrollToTop();
  }

  async setCenter(event: Event | undefined, source: string) {
    if (!this.isBrowser) return;

    console.log('Setting Center', source);

    this.isLoading.set(true);
    const selectedStoreLocation = event?.inStoreInfo?.store?.location;

    // if we have a selected store, center on that store
    if (selectedStoreLocation) {
      console.log('Setting Center on Selected Store', selectedStoreLocation);
      this.center.set(
        new google.maps.LatLng(
          selectedStoreLocation.lat,
          selectedStoreLocation.lon,
        ),
      );
      this.isLoading.set(false);
      return;
    }

    // if we don't have a selected store, center on the user's location
    if (navigator?.geolocation) {
      console.log('Getting Current Position');
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          console.log('Got location', position);
          const pos = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );
          this.center.set(pos);
          this.isLoading.set(false);
        },
        async (err) => {
          console.error('Error getting location', err);
          // if we can't get the user's location, try via the api
          const pos = await this.getLocationFromApi();
          if (pos) {
            this.center.set(pos);
            this.isLoading.set(false);
            return;
          } else {
            // Todo: We might need a way to prompt a reenable of the permissions or prompt instructions for location enable services.
            this.isLoading.set(false);
            return;
          }
        },
      );
      return;
    }

    // if we can't get the user's location, try via the api
    const pos = await this.getLocationFromApi();
    if (pos) {
      this.center.set(pos);
      this.isLoading.set(false);
      return;
    } else {
      // Todo: We might need a way to prompt a reenable of the permissions or prompt instructions for location enable services.
      this.isLoading.set(false);
      return;
    }
  }

  private async getLocationFromApi() {
    console.log('Getting Location from API');
    try {
      const response = await this.userService.apiGetUserGeoLocation();
      if (response) {
        // have coordinates.
        const pos: google.maps.LatLng = new google.maps.LatLng(
          response.lat,
          response.lng,
        );
        return pos;
      }
      return undefined;
    } catch (error) {
      console.error('Error getting location from api', error);
      return undefined;
    }
  }

  initializeSearchBox() {
    if (!this.isBrowser) return;
    let searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement,
    );
    let searchBoxMobile = new google.maps.places.SearchBox(
      this.searchFieldMobile.nativeElement,
    );
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places?.length === 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();
      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      let center = bounds!.getCenter();

      let latMiles = 0.145; // 1 degree of latitude is approximately 69 miles (this is 10 miles)
      let lngMiles = 0.145 / Math.cos((center.lat() * Math.PI) / 180); // 1 degree of longitude varies based on latitude

      let newBounds = {
        north: center.lat() + latMiles,
        south: center.lat() - latMiles,
        east: center.lng() + lngMiles,
        west: center.lng() - lngMiles,
      };

      const biggerBound = new google.maps.LatLngBounds(
        new google.maps.LatLng(newBounds.south, newBounds.west),
        new google.maps.LatLng(newBounds.north, newBounds.east),
      );

      this.searchCoordinates.set(biggerBound);
    });
    searchBoxMobile.addListener('places_changed', () => {
      const places = searchBoxMobile.getPlaces();
      if (places?.length === 0) {
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      let center = bounds!.getCenter();

      let latMiles = 0.145; // 1 degree of latitude is approximately 69 miles (this is 10 miles)
      let lngMiles = 0.145 / Math.cos((center.lat() * Math.PI) / 180); // 1 degree of longitude varies based on latitude

      let newBounds = {
        north: center.lat() + latMiles,
        south: center.lat() - latMiles,
        east: center.lng() + lngMiles,
        west: center.lng() - lngMiles,
      };

      const biggerBound = new google.maps.LatLngBounds(
        new google.maps.LatLng(newBounds.south, newBounds.west),
        new google.maps.LatLng(newBounds.north, newBounds.east),
      );

      this.searchCoordinates.set(biggerBound);
    });
  }

  /// handled by computed signal storesToShow
  // shiftSelectedStoreIntoView(event: Event | undefined) {
  //   console.log('Shift Selected Store Into View');
  //   const storeInEvent = event?.inStoreInfo?.store;
  //   if (storeInEvent !== undefined) {
  //     if (this.reorderStores()) {
  //       this.storesToShow.update((stores) => {
  //         const storeInEventMarker = stores.find((store) => store.actnum === storeInEvent?.actnum);
  //         console.log('New First Store', storeInEventMarker);
  //         const newStoreMarkers = storeInEventMarker
  //           ? [storeInEventMarker, ...stores.filter((store) => store.actnum !== storeInEvent?.actnum)]
  //           : stores;
  //         return newStoreMarkers;
  //       });

  //       // let copiedStore: StoreMarker | null = null;
  //       // stores.forEach((store: StoreMarker, index: number) => {
  //       //   if (store.store.actnum === storeInEvent?.actnum) {
  //       //     copiedStore = store;
  //       //     stores.splice(index, 1);
  //       //   }
  //       // });
  //       // if (copiedStore !== null) {
  //       //   stores.unshift(copiedStore);
  //       // }
  //       // return stores;

  //     }

  //     this.saveSelectingStoreIndex.set(new Array(this.cachedStoreMarkers().length).fill(false));

  //     if (this.scrollSelectedStoreIntoView()) {
  //       console.log("Scrolling to Store");
  //       setTimeout(() => {
  //         const stepElement = this.document.getElementById('selectedStore');
  //         if (stepElement) {
  //           setTimeout(() => {
  //             stepElement.scrollIntoView({
  //               block: 'start',
  //               inline: 'nearest',
  //               behavior: 'auto',
  //             });
  //           }, 1);
  //         }
  //       });
  //     }
  //   } else {
  //     console.log('No Store in Event');
  //   }
  // }

  populateStoreList(data: {
    storeMarkersInBox: StoreMarker[];
    cachedStoreMarkers: StoreMarker[];
  }) {
    const { storeMarkersInBox, cachedStoreMarkers } = data;
    this.cachedStoreMarkers.set(cachedStoreMarkers);
    this.storeMarkersInBox.set(storeMarkersInBox);
    this.saveSelectingStoreIndex.set(
      new Array(cachedStoreMarkers.length).fill(false),
    );

    // this.storesToShow.set(storesToShow);
    // this.shiftSelectedStoreIntoView(this.event);
  }

  toggleMap() {
    this.showMap.update((val) => !val);
  }

  getUserLocation() {
    if (!this.isBrowser) return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          let pos = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );
          this.center.set(pos);
        },
        (err) => {
          if (err.PERMISSION_DENIED) {
            alert(
              'Please enable location permissions using the icon left of the browser url to use this feature.',
            );
          }
        },
      );
    }
  }

  async getLocationByZipcode() {
    if (!this.isBrowser) return;
    await this.geocoder
      ?.geocode({
        address: this.zipcode,
        componentRestrictions: {
          country: 'US',
        },
      })
      .then((loc) => {
        let lat = loc.results[0].geometry.location.lat();
        let lng = loc.results[0].geometry.location.lng();
        let center = new google.maps.LatLng(lat, lng);
        this.center.set(center);
        // this.map?.setCenter(center);
        // reset zip? for now
        this.zipcode = '';
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  storeDetails(store: DealerAccountElastic): void {
    if (!this._event()) {
      let encodedName = '';
      if (store.aname != undefined) {
        let hyphenatedName = store.aname.replaceAll(' ', '-');
        encodedName = encodeURIComponent(hyphenatedName).replace(
          /[!'()~]/g,
          escape,
        );
      }
      console.log(`encodedName: ${encodedName}`);
      this.router.navigate([`/store-detail/${encodedName}-${store.actnum}`]);
    } else {
      const modalRef = this.dialog.open(StoreInfoModalComponent, {
        data: store,
        minWidth: '240px',
        maxWidth: '95%',
        panelClass: 'dialog-modal',
        maxHeight: '90%',
        autoFocus: false,
      });

      modalRef.componentInstance.storeSelected.subscribe(
        (store: DealerAccountElastic) => {
          if (store) {
            this.selectStore(store);
            this.dialog.closeAll();
          }
        },
      );
    }
  }

  async selectStore(
    store: DealerAccountElastic,
    index?: number,
  ): Promise<void> {
    const event = this._event();
    /// try to find the index if the index is not provided
    const indexToSelect = this.storesToShow().findIndex(
      (storeToShow) => storeToShow.actnum === store.actnum,
    );
    this.updateSavingIndex(index ?? indexToSelect, true);

    if (event === undefined) {
      return;
    }
    try {
      const { updatedEvent } = await this.storeService.setStoreForEvent(
        event,
        store,
      );
      this.storeToHighlight.set('');

      this.eventService.setSelectedEventWithEvent(
        updatedEvent,
        'StoreLocatorComponent - selectStore',
      );
      this.updateSavingIndex(index ?? indexToSelect, false);
      setTimeout(() => {
        this.storeSelected.emit();
      }, 2000);
    } catch (error) {
      alert('Something went wrong selecting a store.');
      this.updateSavingIndex(index ?? indexToSelect, false);
    }
  }

  updateSavingIndex(index: number | undefined, isSaving: boolean) {
    if (index === undefined || index === -1) {
      return;
    }
    this.saveSelectingStoreIndex.update((current) =>
      current.map((value, i) => (i === index ? isSaving : value)),
    );
    console.log('Saving Index', this.saveSelectingStoreIndex());
  }

  openStoreDetails(storeId: string | undefined): void {
    this.router.navigate([`/store-detail/${storeId}`]);
  }

  scrollToStore(storeName: string): void {
    this.storeToHighlight.set(storeName);
    if (this.isMobile()) {
      setTimeout(() => {
        const stepElement = this.document.getElementById(`store-${storeName}`);
        if (stepElement) {
          setTimeout(() => {
            stepElement.scrollIntoView({
              block: 'center',
              inline: 'nearest',
              behavior: 'smooth',
            });
          }, 0);
        }
      }, 500);
    }
  }
}
