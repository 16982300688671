import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReCaptchaScoreService } from './recaptcha-score.service';
import { RECAPTCHA_BASE_URL } from './tokens';

@NgModule({
  declarations: [],
  imports: [ ],
  exports: [],
  providers: [
    ReCaptchaScoreService,
    {provide: RECAPTCHA_BASE_URL, useValue: 'https://www.google.com/recaptcha/enterprise.js'}
  ],
})
export class ReCaptchaScoreModule {}
