<div
  class="main-container"
  [ngClass]="[fillHeightAndWidth ? 'fill-height-and-width' : 'fill-width-only']"
>
  <button
    *ngIf="canEnlarge"
    class="enlarge-button"
    (click)="onEnlarge($event)"
    mat-icon-button
  >
    <mat-icon>zoom_out_map</mat-icon>
  </button>
  <img
    [ngClass]="getClass()"
    ngSrc="spacer-2-3.gif"
    alt="mannequin-image-spacer"
    sizes="(max-width: 960px) 50%, 65%"
    fill
  />

  <div class="mannequin">
    <img
      [ngClass]="getClass()"
      ngSrc="TORSO_MASTER_NO_BOTTOM.png"
      alt="mannequin-image-no-bottom"
      sizes="(max-width: 960px) 50%, 65%"
      fill
    />
  </div>

  <ng-container *ngFor="let mannequinImageType of mannequinImageTypes">
    <ng-container *ngFor="let style of styles">
      <ng-container
        *ngFor="
          let image of style.mannequinImages!
            | mannequinImage: mannequinImageType
        "
      >
        <div class="mannequin">
          <img
            [ngClass]="getClass()"
            [@styleImage]="getAnimation()"
            ngSrc="{{ getImage(image.src) }}"
            alt="{{ image.alt }}"
            sizes="(max-width: 960px) 50%, 65%"
            fill
          />
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <div *ngIf="canSelect || saving" class="select-icon">
    <ng-container *ngIf="!saving; else spinner">
      <mat-icon
        tabIndex="0"
        *ngIf="!selected && !disableSelect"
        data-cy="select-look"
        >radio_button_unchecked</mat-icon
      >
      <mat-icon *ngIf="selected" tabIndex="0">radio_button_checked</mat-icon>
      <mat-icon *ngIf="!selected && disableSelect">block</mat-icon>
    </ng-container>
    <ng-template #spinner>
      <mat-spinner diameter="20"></mat-spinner>
    </ng-template>
  </div>
</div>
