import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { generateUUID } from 'business-logic';
import {
  AvalaraEstimateRequest,
  AvalaraLineItem,
  AvalaraRecordRequest,
  Cart,
  EventMember,
  JfwTaxEstimateRequest,
  JfwTaxEstimateResponse,
  JfwTaxRecordRequest,
  MemberCart,
  MemberLook,
  MemberLookStyle,
  ShippingAddress,
  StyleGroup,
  StyleType,
  TaxAddress,
} from 'common-types';
import { TaxApiService } from '../tax-api/tax-api.service';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(private taxApiService: TaxApiService) { }

  private isRentingCoat(memberLookStyles: MemberLookStyle[]) {
    let isRentingCoat: boolean = false;

    memberLookStyles.forEach((style) => {
      if (
        style.styleType === StyleType.Coat &&
        style.styleGroup === StyleGroup.Rental
      ) {
        isRentingCoat = true;
      }
    });

    return isRentingCoat;
  }

  private excludeStyleFromTaxLines(
    memberLookStyle: MemberLookStyle,
    isRentingCoat: boolean,
  ): boolean {
    if (memberLookStyle.styleType === StyleType.Coat) {
      return false;
    }

    if (!isRentingCoat) {
      return false;
    }

    if (
      isRentingCoat &&
      memberLookStyle.styleType === StyleType.Jewelry &&
      memberLookStyle.collection !== 'Premium (PRM)'
    ) {
      return true;
    }

    if (isRentingCoat && memberLookStyle.styleGroup === StyleGroup.Purchase) {
      return false;
    }

    if (
      isRentingCoat &&
      (memberLookStyle.styleType === StyleType.Pant ||
        memberLookStyle.styleType === StyleType.Shirt ||
        memberLookStyle.styleType === StyleType.Tie ||
        memberLookStyle.styleType === StyleType.Vest)
    ) {
      return true;
    }

    return false;
  }

  private getTaxCode(style: MemberLookStyle) {
    switch (style.styleGroup) {
      case StyleGroup.Rental:
        return 'PR060298';
        break;

      case StyleGroup.Purchase:
        return 'PC040118';
        break;

      default:
        return '';
    }
  }

  private getLinesForMemberTax(
    memberLook: MemberLook,
    damageWaiver: number,
    shippingCost: number,
  ): AvalaraLineItem[] {
    let lines: AvalaraLineItem[] = [];

    if (memberLook.styles === undefined) {
      return lines;
    }

    let isRentingCoat: boolean = this.isRentingCoat(memberLook.styles);

    console.log('Renting Coat:', isRentingCoat);

    let lineNumber: number = 0;

    memberLook.styles.forEach((style) => {
      if (!this.excludeStyleFromTaxLines(style, isRentingCoat)) {
        let line: AvalaraLineItem = {
          amount: style.price!,
          description:
            style.styleGroup + ' - ' + style.styleType + ': ' + style.styleCode,
          itemCode: style.styleCode,
          number: 'line' + lineNumber.toString(),
          quantity: 1,
          taxCode: this.getTaxCode(style),
        };

        lines.push(line);
      } else {
        let line: AvalaraLineItem = {
          amount: 0,
          description:
            style.styleGroup + ' - ' + style.styleType + ': ' + style.styleCode,
          itemCode: style.styleCode,
          number: 'line' + lineNumber.toString(),
          quantity: 1,
          taxCode: this.getTaxCode(style),
        };

        lines.push(line);
      }

      lineNumber++;
    });

    let damageWaiverLine: AvalaraLineItem = {
      amount: damageWaiver,
      description: 'Damage Waiver',
      itemCode: 'DW',
      number: 'line' + lineNumber.toString(),
      quantity: 1,
      taxCode: 'PR060298',
    };

    lines.push(damageWaiverLine);

    lineNumber++;

    let shippingLine: AvalaraLineItem = {
      amount: shippingCost,
      description: 'Standard Shipping (UPS Ground Two Ways)',
      itemCode: 'SH',
      number: 'line' + lineNumber.toString(),
      quantity: 1,
      taxCode: 'PR060298',
    };

    lines.push(shippingLine);

    return lines;
  }

  private getShippingAddressFromCartAddressForms(
    cart: Cart,
    member: MemberCart,
  ): ShippingAddress | undefined {
    if (cart.shippingAddressForms === undefined) {
      return undefined;
    }

    for (const form of cart.shippingAddressForms) {
      let associatedMemberIds = form.associatedMemberIds;

      if (associatedMemberIds === undefined) {
        return undefined;
      }

      for (const memberId of associatedMemberIds) {
        if (memberId === member.id) {
          return form.shippingAddress;
        }
      }
    }

    return undefined;
  }

  private getAddressUsedForTax(
    cart: Cart,
    memberCart: MemberCart,
  ): TaxAddress | undefined {
    if (
      cart.members?.length === 1 &&
      memberCart.member.shippingAddress !== undefined
    ) {
      let address: TaxAddress = {
        address1: memberCart.member.shippingAddress.streetAddress1,
        city: memberCart.member.shippingAddress.city,
        state: memberCart.member.shippingAddress.state,
        zip: memberCart.member.shippingAddress.zip,
      };

      return address;
    }

    if (
      cart.members !== undefined &&
      cart.members.length > 1 &&
      cart.shippingAddressForms !== undefined &&
      cart.shippingAddressForms.length > 0
    ) {
      let addressFromForm = this.getShippingAddressFromCartAddressForms(
        cart,
        memberCart,
      );

      if (addressFromForm === undefined) {
        return undefined;
      }

      let address: TaxAddress = {
        address1: addressFromForm.streetAddress1,
        city: addressFromForm.city,
        state: addressFromForm.state,
        zip: addressFromForm.zip,
      };

      return address;
    }

    return undefined;
  }

  private async getTaxEstimateRequestForMember(
    eventId: string,
    member: EventMember,
    address: TaxAddress,
    damageWaiver: number,
    shippingCost: number,
  ): Promise<JfwTaxEstimateRequest | undefined> {
    if (
      member.id === undefined ||
      member.memberLook === undefined ||
      member.memberLook.styles === undefined
    ) {
      return undefined;
    }

    let memberLines = this.getLinesForMemberTax(
      member.memberLook,
      damageWaiver,
      shippingCost,
    );

    let avaleraRequestType: AvalaraEstimateRequest = {
      amount: 0,
      lines: memberLines,
      address: address,
    };

    let avalaraTaxEstimateRequest: JfwTaxEstimateRequest = {
      eventId: eventId,
      memberId: member.id,
      submittedDate: Date.now(),
      avalaraEstimateRequest: avaleraRequestType,
    };

    return avalaraTaxEstimateRequest;
  }

  public updateCartTotalTax(cart: Cart) {
    let totalCartTax: number = 0.0;

    console.log('starting tax:' + totalCartTax);

    cart.members?.forEach((member) => {
      if (member.taxes > 0) {
        console.log('members tax:' + member.taxes);
        totalCartTax = totalCartTax + member.taxes;
      }
    });

    console.log('Total Cart Tax: ' + totalCartTax);
    cart.taxes = Number(totalCartTax.toFixed(2));

    return cart;
  }

  public getDamageWaiverFee(member: EventMember) {
    let foundRental: boolean = false;

    member.memberLook?.styles.forEach((style) => {
      if (style.userCartSelectedStyleGroup === StyleGroup.Rental) {
        foundRental = true;
      }
    });

    if (foundRental) {
      return 10;
    }

    return 0;
  }

  async getCartEstimateTaxes(cart: Cart): Promise<Cart | undefined> {
    //return new Promise(async (resolve: any, reject: any) => {
    let taxRequests: JfwTaxEstimateRequest[] = [];
    let taxResponses: JfwTaxEstimateResponse[] = [];

    let totalCartTax: number = 0;
    let errorsEncountered: boolean = false;

    if (cart && cart.members) {
      for (const member of cart.members) {
        console.log('Member', member);

        let addressType: TaxAddress | undefined = this.getAddressUsedForTax(
          cart,
          member,
        );

        console.log('Address Used for Member');
        console.log(addressType);

        if (addressType === undefined) {
          continue;
        }

        let damageWaiver: number = this.getDamageWaiverFee(member.member);

        let taxRequest = await this.getTaxEstimateRequestForMember(
          cart.eventId,
          member.member,
          addressType,
          damageWaiver,
          cart.shippingCost,
        );

        if (taxRequest !== undefined) {
          let taxResponse =
            await this.taxApiService.apiEstimateTaxV2(taxRequest);

          if (
            taxResponse === undefined ||
            taxResponse.status.toLowerCase() !== 'success'
          ) {
            console.log('getTaxes returned error');
            errorsEncountered = true;
            break;
          }

          if (taxResponse) {
            console.log('totalTax for member');
            console.log(taxResponse.totalTax);
            member.taxEstimateRequest = taxRequest;
            member.taxEstimateResponse = taxResponse;

            if (taxResponse.totalTax !== undefined) {
              member.taxes = taxResponse.totalTax;
              member.total = member.subtotal + member.taxes;
              totalCartTax += taxResponse.totalTax;
            }

            taxRequests.push(taxRequest);
            taxResponses.push(taxResponse);
          }
        }
      }
    }
    console.log('Tax Requests');
    console.log(taxRequests);

    console.log('Tax Responses');
    console.log(taxResponses);

    //this.cartService.setCartInLocalStorage(this.cart);

    if (errorsEncountered) {
      return undefined;
    } else {
      return cart;
    }
  }

  private async getTaxRecordRequestForMember(
    eventId: string,
    eventDate: number,
    cartId: string,
    member: EventMember,
    address: TaxAddress,
    damageWaiver: number,
    shippingCost: number,
  ): Promise<JfwTaxRecordRequest | undefined> {
    if (
      member.id === undefined ||
      member.memberLook === undefined ||
      member.memberLook.styles === undefined
    ) {
      return undefined;
    }

    let memberLines = this.getLinesForMemberTax(
      member.memberLook,
      damageWaiver,
      shippingCost,
    );

    let randomGuid = generateUUID(this.isBrowser);

    console.log('random guid');
    console.log(randomGuid);

    let referenceCode = {
      eventId: eventId,
      eventDate: eventDate,
      eventMemberId: member.id,
      cartId: cartId,
    };

    let avalaraRecordRequest: AvalaraRecordRequest = {
      referenceNumber: member.id,
      customerCode: member.id,
      purchaseOrderNo: cartId,
      lineItems: memberLines,
      address: address,
      referenceCode: JSON.stringify(referenceCode),
    };

    let jfwTaxRecordRequest: JfwTaxRecordRequest = {
      eventId: eventId,
      cartId: cartId,
      memberId: member.id,
      submittedDate: Date.now(),
      avalaraRecordRequest: avalaraRecordRequest,
    };

    return jfwTaxRecordRequest;
  }

  // async getCartRecordTaxes(
  //   cart: Cart,
  //   event: Event
  // ): Promise<Cart | undefined> {
  //   //return new Promise(async (resolve: any, reject: any) => {
  //   let taxRequests: JfwTaxRecordRequest[] = [];
  //   let taxResponses: JfwTaxRecordResponse[] = [];

  //   let totalCartTax: number = 0;
  //   let errorsEncountered: boolean = false;

  //   if (cart && cart.members) {
  //     for (const member of cart.members) {
  //       console.log('Member', member);

  //       let addressType: TaxAddress | undefined = this.getAddressUsedForTax(
  //         cart,
  //         member
  //       );

  //       console.log('Address Used for Member');
  //       console.log(addressType);

  //       if (addressType === undefined) {
  //         continue;
  //       }

  //       let taxRequest = await this.getTaxRecordRequestForMember(
  //         cart.eventId,
  //         event.eventDate,
  //         cart.id,
  //         member.member,
  //         addressType,
  //         cart.damageWaiver,
  //         cart.shippingCost
  //       );

  //       if (taxRequest !== undefined) {
  //         let taxResponse = await this.taxApiService.apiRecordTaxV2(taxRequest);

  //         if (
  //           taxResponse === undefined ||
  //           taxResponse.status.toLowerCase() !== 'success'
  //         ) {
  //           console.log('apiRecordTaxV2 returned error');
  //           errorsEncountered = true;
  //           break;
  //         }

  //         if (taxResponse) {
  //           member.taxRecordRequest = taxRequest;
  //           member.taxRecordResponse = taxResponse;

  //           if (taxResponse.totalTax) {
  //             member.taxes = taxResponse.totalTax;
  //             member.total = member.subtotal + member.taxes;
  //             totalCartTax += taxResponse.totalTax;
  //           }

  //           taxRequests.push(taxRequest);
  //           taxResponses.push(taxResponse);
  //         }
  //       }
  //     }
  //   }
  //   console.log('Tax Record Requests');
  //   console.log(taxRequests);

  //   console.log('Tax Record Responses');
  //   console.log(taxResponses);

  //   //this.cartService.setCartInLocalStorage(this.cart);

  //   if (errorsEncountered) {
  //     return undefined;
  //   } else {
  //     return cart;
  //   }
  // }

  // ----- //

  // public estimateTax(): Observable<number> {
  //   const requestData: AvalaraEstimateRequest = {
  //     amount: 100,
  //     address: {
  //       address1: '211 e fairway',
  //       city: 'Belleville',
  //       state: 'il',
  //       zip: '62223',
  //     },
  //     lines: [],
  //   };

  //   var tax = 0;

  //   return this.taxApiService
  //     .apiEstimateTax(requestData)
  //     .pipe(map((item) => item.tax ?? 0));
  // }
}
