import { isPlatformServer } from '@angular/common';
import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@jfw-library/ecommerce/core';

@Injectable({
  providedIn: 'root',
})
export class AuthRequiredGuard {
  isServer = isPlatformServer(inject(PLATFORM_ID));
  constructor(
    @Inject('environment') private environment: any,
    private router: Router,
    private authService: AuthService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    console.log(
      '%c* AuthRequiredGuard START *',
      'background-color: orange;color:black',
    );

    if (this.isServer) {
      return false;
    }
    const isLoggedIn = await this.authService.isLoggedIn;

    console.log('AuthRequiredGuard -> isLoggedIn:', isLoggedIn);

    if (isLoggedIn) {
      console.log('AuthRequiredGuard -> LOGGED IN');
      const allowed = await this.authService.getUserHasEcomAccess(true);

      console.log('AuthRequiredGuard -> allowed:', allowed);

      if (allowed) {
        console.log('AuthRequiredGuard -> ALLOWED');
        return true;
      }
    }

    console.log('AuthRequiredGuard -> NOT ALLOWED');

    if (!this.authService.isProdModeWideOpen) {
      await this.router.navigate(['V2login']);
      return false;
    } else {
      await this.router.navigate(['home']);
      return false;
    }
  }
}
