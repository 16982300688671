<div class="modal-main">
  <div class="close-modal">
    <button
      mat-icon-button
      mat-dialog-close
      class="close-button"
      [disabled]="showSpinner()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-header-container">
    <h3>Name Your Look and<br />Select Your Event</h3>
  </div>

  <div class="event-modal-main">
    <mat-dialog-content style="color: black">
      <div
        style="
          text-align: left;
          width: 100%;
          margin-bottom: 10px;
          font-size: 14px;
        "
      >
        * Required
      </div>
      <form [formGroup]="form">
        <mat-form-field appearance="outline" hideRequiredMarker>
          <mat-label>Look Name*</mat-label>
          <input matInput formControlName="lookName" />
          <mat-error>Look name is required.</mat-error>
        </mat-form-field>

        @if (allowEventCreation) {
          <p><strong>What type of event are you planning?</strong></p>
          <button
            mat-stroked-button
            class="big-button"
            type="button"
            (click)="onNew()"
            [disabled]="showSpinner()"
          >
            NEW EVENT
          </button>
        }

        <p>
          <strong>Select the event where you'd like to save this look.</strong>
        </p>
        <mat-progress-spinner
          *ngIf="showSpinner()"
          class="progress-spinner"
          value="100"
          mode="indeterminate"
          color="primary"
          diameter="40"
          strokeWidth="5"
        ></mat-progress-spinner>

        <ng-container *ngIf="useEventSelect$ | async; else useButtonToggle">
          <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>EXISTING EVENT</mat-label>
            <mat-select formControlName="eventSelect">
              <ng-container *ngFor="let event of existingEvents$ | async">
                <!-- This use to be inside mat-option -->
                <!-- matTooltip="{{ formatEventWithDate(event) }}" -->
                <mat-option value="{{ event.id }}">
                  {{ formatEventWithDate(event) }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <ng-template #useButtonToggle>
          <mat-button-toggle-group
            formControlName="eventButtonToggle"
            vertical
            [disabled]="showSpinner()"
          >
            <ng-container *ngFor="let event of existingEvents$ | async">
              <mat-button-toggle value="{{ event.id }}">
                {{ formatEventWithDate(event) }}
              </mat-button-toggle>
            </ng-container>
          </mat-button-toggle-group>
        </ng-template>

        <mat-error style="margin-top: 10px" *ngIf="showEventError()"
          >Please select an event or create a new one.</mat-error
        >

        <button
          mat-flat-button
          color="primary"
          (click)="onExisting()"
          [disabled]="!form.valid || showSpinner()"
          style="margin-top: 20px"
        >
          NEXT <mat-icon>arrow_forward</mat-icon>
        </button>
      </form>
    </mat-dialog-content>
  </div>
</div>
