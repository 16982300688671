import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  UpdateDealerCommunicationPrefsData,
  UpdateDealerCommunicationPrefsErrorCode,
  UpdateDealerCommunicationPrefsResponse,
  UpdateEcomCommunicationPrefsData,
  UpdateEcomCommunicationPrefsErrorCode,
  UpdateEcomCommunicationPrefsResponse,
  UpdatePersonalInfoData,
  UpdatePersonalInfoResponse,
} from 'common-types';
import { Observable, catchError, of } from 'rxjs';
// import { AuthErrorCode } from '@common-types/src/firebase-errors/firebase-auth-error-code.type';

@Injectable({
  providedIn: 'root',
})
export class UpdateUserAccountService {
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  public updatePersonalInfo(
    data: UpdatePersonalInfoData
  ): Observable<UpdatePersonalInfoResponse> {
    const url = `${this.environment.update_user_account_server_url}/personal-info`;

    return this.httpClient
      .post<UpdatePersonalInfoResponse>(url, data, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.error(err.error);
          const apiError = err.error?.error;
          if (apiError) return of(err.error); // If API error, return error

          // Otherwise, error is client-side
          const errorResponse: UpdateEcomCommunicationPrefsResponse = {
            error: {
              code: UpdateEcomCommunicationPrefsErrorCode.CLIENT,
              message: err.error,
            },
            updates: null,
          };
          return of(errorResponse);

          // throw err.error;
          // return of(err.error);
        })
      );
    // return of(data?.email).pipe(
    //   switchMap((email) => { // Handle email update first
    //     const user = this.auth.currentUser;
    //     if(email) {
    //       if(user) {
    //         console.log("Updating email...");
    //         return from(updateEmail(user, email)).pipe( // Update email
    //           catchError((error) => { // Handle update email error
    //             console.error("Error updating email:",  error);
    //             throw error;
    //           }), // If error, return undefined
    //           tap(() => console.log("Email updated.  Sending verification email")),
    //           switchMap(() => from(sendEmailVerification(user))), // Send verification email
    //           map(() => data), // Return original data for rest of updates
    //           catchError((error, updates) => {
    //             console.error("Error sending email verification:",  error);
    //             // After a successful email update, token will be invalid.
    //             if(error === AuthErrorCode['auth/user-token-expired']) {
    //               console.log("Token expired.");
    //               return updates;
    //             }

    //             // otherwise throw error
    //             throw error;
    //           })

    //         );
    //       }

    //       else throw new Error('No user found');
    //     }
    //     else {
    //       console.log("No email update");
    //       return of(data);
    //     }
    //   }),
    //   switchMap((data) => { // Handle rest of updates
    //     return this.httpClient.post<UpdatePersonalInfoResponse>(url, data, { headers: this.reqHeader }).pipe(
    //       catchError((err: HttpErrorResponse) => {
    //         console.error(err.error);
    //         return of(err.error);
    //       })
    //     );
    //   }),
    // )
  }

  public updateEcomCommunicationPrefs(
    data: UpdateEcomCommunicationPrefsData
  ): Observable<UpdateEcomCommunicationPrefsResponse> {
    const url = `${this.environment.update_user_account_server_url}/communication-prefs-ecom`;

    return this.httpClient
      .post<UpdateEcomCommunicationPrefsResponse>(url, data, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.error(err.error);
          const apiError = err.error?.error;
          if (apiError) return of(err.error); // If API error, return error

          // Otherwise, error is client-side
          const errorResponse: UpdateEcomCommunicationPrefsResponse = {
            error: {
              code: UpdateEcomCommunicationPrefsErrorCode.CLIENT,
              message: err.error,
            },
            updates: null,
          };
          return of(errorResponse);
        })
      );
  }

  public updateDealerCommunicationPrefs(
    dealerId: string,
    data: UpdateDealerCommunicationPrefsData
  ): Observable<UpdateDealerCommunicationPrefsResponse> {
    const url = `${this.environment.update_user_account_server_url}/communication-prefs-dealer/${dealerId}`;

    return this.httpClient
      .post<UpdateDealerCommunicationPrefsResponse>(url, data, {
        ...requireAppCheck,
        headers: this.reqHeader,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.error(err.error);
          const apiError = err.error?.error;
          if (apiError) return of(err.error); // If API error, return error

          // Otherwise, error is client-side
          const errorResponse: UpdateDealerCommunicationPrefsResponse = {
            error: {
              code: UpdateDealerCommunicationPrefsErrorCode.CLIENT,
              message: err.error,
            },
            updates: null,
          };
          return of(errorResponse);
        })
      );
  }
}
