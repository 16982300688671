<div class="parent">
  <div class="event-name-container">
    <span class="event-name">
      {{ event?.eventName | uppercase }}
    </span>
    <div class="vertical-line"></div>
    <span class="event-date">
      {{ event ? (event.eventDate * 1000 | date : "MMMM d, y") : '' }}
    </span>

    <ng-container
      *ngIf="!isTrackProgressPage && trackProgressEnabled && !inStoreView"
    >
      <div class="vertical-line"></div>
      <span class="track-progress">
        <a [routerLink]="['/event', event?.id, 'track-progress']"
          >Track Progress</a
        >
      </span>
    </ng-container>
    <ng-container *ngIf="!memberView || dealerPortal">
      <div class="vertical-line"></div>
      <span class="edit-details">
        <a [routerLink]="['/event', event?.id, 'details']">Event Details</a>
      </span>
    </ng-container>
  </div>
</div>
