import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MeasurementApiService {
  private MEASUREMENT_REST_API_SERVER =
    this.environment.measurement_rest_api_server_url;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  public apiGetMeasurementReference(
    memberId: string,
    email: string,
    shoeSize: string
  ): Observable<any> {
    const apiUrl = `${
      this.MEASUREMENT_REST_API_SERVER
    }/save/?memberId=${encodeURIComponent(memberId)}&email=${encodeURIComponent(
      email
    )}&shoeSize=${encodeURIComponent(shoeSize)}`;

    return this.httpClient.get(apiUrl, { ...requireAppCheck }).pipe(
      tap((x: any) =>
        console.log(`measurements response: ${JSON.stringify(x)}`)
      ),
      catchError(this.handleError)
    );
  }

  public apiGetMeasurements(memberId: string): Observable<any> {
    const apiUrl = `${
      this.MEASUREMENT_REST_API_SERVER
    }/measurements?memberId=${encodeURIComponent(memberId)}`;

    return this.httpClient.get(apiUrl, { ...requireAppCheck }).pipe(
      // tap((x: any) => console.log(`raw measurements response: ${JSON.stringify(x)}`)),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }
}
