import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { ECOM_EVENT_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import {
  DealerAccountElastic,
  EcommerceMainEnvironment,
  Event,
  ShareEventDetails,
  Store
} from 'common-types';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private ecomUpdateEventApiService = inject(ECOM_EVENT_API_SERVICES.v7);
  private STORE_REST_API_SERVER = this.environment.store_rest_api_server_url;
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    @Inject('environment') private environment: EcommerceMainEnvironment,
    private httpClient: HttpClient,
  ) { }

  public getStoresByActNum(actNum: string): Observable<Store[]> {
    const apiEndpoint: string = `${this.STORE_REST_API_SERVER}/${actNum}`;
    return this.httpClient.get<Store[]>(apiEndpoint, {
      headers: this.reqHeader,
    });
  }

  public setStoreForEvent(event: Event, store: DealerAccountElastic) {
    return firstValueFrom(
      this.ecomUpdateEventApiService.updateInStoreInfo(event.id, { store }),
    );
  }

  public changeRetailerForEvent(event: Event) {
    return firstValueFrom(
      this.ecomUpdateEventApiService.deleteStoreFromInStoreInfoFromEvent(
        event.id,
      ),
    );
  }

  public updateShareEventDetails(
    eventId: string,
    // inStoreInfo: InStoreInfo | undefined,
    shareEventDetails: ShareEventDetails,
  ) {
    // if (inStoreInfo == undefined) {
    //   return;
    // }
    return firstValueFrom(
      this.ecomUpdateEventApiService.updateInStoreInfo(eventId, {
        shareEventDetails,
      }),
    );
  }

  /// MOVED to EventManagerService
  /*   public setDateSharedWithStore(event: Event, dateSharedWithStore: number) {
      if (event.inStoreInfo == undefined) {
        return;
      }
      const updatedInStoreInfo: Pick<InStoreInfo, 'dateSharedWithStore'> = {
        dateSharedWithStore,
      };
      return firstValueFrom(
        this.ecomUpdateEventApiService.updateInStoreInfo(
          event.id,
          updatedInStoreInfo,
        ),
      );
    }
   */
  public getStoresInBox(
    centerLat: number,
    centerLng: number,
    left: number,
    right: number,
    bottom: number,
    top: number,
  ): Observable<Store[]> {
    const apiEndpoint: string = `${this.STORE_REST_API_SERVER}/geo/${centerLat}/${centerLng}/${left}/${right}/${bottom}/${top}/`;
    return this.httpClient.get<Store[]>(apiEndpoint, {
      headers: this.reqHeader,
    });
  }
}
