import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EcomStyle, MannequinImageType } from 'common-types';

@Component({
  selector: 'app-look-mannequin',
  templateUrl: './look-mannequin.component.html',
  styleUrls: ['./look-mannequin.component.scss'],
  animations: [
    trigger('styleImage', [
      state('remove', style({ opacity: 0 })),
      transition('void => animate', [
        style({
          opacity: 0,
          'z-index': 1000,
          transform: 'scale(0.5) translateX(1200px) translateY(-50px)',
          border: 'none',
        }),
        animate(
          '400ms ease-in-out',
          style({
            opacity: 0.5,
            transform: 'translateX(1px) translateY(1px) scale(1)',
          })
        ),
        animate(
          '200ms ease-in-out',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('animate => void', [
        style({ opacity: 1, border: 'none' }),
        animate('1000ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LookMannequinComponent implements OnChanges {
  @Input() styles: EcomStyle[] | undefined;
  @Input() fillHeightAndWidth = false;
  @Input() canSelect = false;
  @Input() selected = false;
  @Input() canEnlarge = false;
  @Input() disableSelect = false;
  @Input() required = false;
  @Input() useAnimation = false;
  @Input() saving = false;
  @Output() enlargeLook = new EventEmitter<void>();

  previousStyles: EcomStyle[] | undefined;
  mannequinImageTypes = Object.values(MannequinImageType);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.styles !== undefined) {
      this.previousStyles = changes.styles.previousValue;
    }
  }

  public onEnlarge(clickEvent: Event): void {
    clickEvent.stopPropagation();
    this.enlargeLook.emit();
  }

  public getClass(): string {
    if (this.selected) {
      return 'mannequin-image selected';
    } else if (this.required && !this.disableSelect) {
      return 'mannequin-image required';
    } else {
      return 'mannequin-image';
    }
  }

  public getAnimation(): string {
    return this.useAnimation ? 'animate' : 'noanimate';
  }

  getImage(imageUrl: string): string {
    let newUrl: string = imageUrl.trim();

    newUrl = newUrl.replace(
      /https:\/\/www.jimsformalwear.com\/images\/1280x1920\//g,
      ''
    );

    newUrl = newUrl.replace(
      /https:\/\/www.jimsformalwear.com\/images\/415x623\//g,
      ''
    );

    // newUrl = newUrl.replace(
    //   /https:\/\/www.jimsformalwear.com\/images\/undefinedxNaN\//g,
    //   ''
    // );

    newUrl = newUrl.replace(/https:\/\/www.jimsformalwear.com\/\_\/img\//g, '');

    newUrl = newUrl.replace(/assets\/product-images\//g, '');

    return newUrl;
  }
}
