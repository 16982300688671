<mat-radio-group required [disabled]="submitted">
  <div class="digital-tool">
    <mat-radio-button
      value="tool"
      (click)="setDerivedFrom($event, 'tool')"
      [checked]="isDerivedFrom('tool')"
    >
      <span class="radio-button-content"> Digital Measurement Tool </span>
    </mat-radio-button>
    <div
      [ngClass]="
        isDerivedFrom('tool') ? 'digital-tool-button-container' : 'hidden'
      "
    >
      <div class="strong-text-container">
        <strong class="dgm-text"
          >Complete digital measurement on your mobile device. Return to your
          desktop to complete your order.</strong
        >
      </div>

      <div class="digital-tool-field" (click)="$event.stopPropagation()">
        <form *ngIf="form" [formGroup]="form">
          <mat-form-field class="digital-shoe-field" appearance="outline">
            <mat-label>Shoe Size</mat-label>
            <input
              type="text"
              tabindex="0"
              placeholder="Shoe Size"
              aria-label="Shoe Size"
              matInput
              formControlName="shoeSize"
              [matAutocomplete]="autoShoeSize"
              matAutocompletePosition="below"
            />
            <mat-autocomplete #shoeSize #autoShoeSize="matAutocomplete" (closed)="onClose('shoeSize')">
              <mat-option
                *ngFor="let option of shoeSizeFilteredOptions | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>

        <app-measurements-tool
          class="measurement-tool"
          #widget
          publicKey="{{ threeDLookPublicKey }}"
          email="{{ email }}"
          memberId="{{ memberId }}"
          [shoeFieldRequired]="hasShoesInLooks()"
          (shoeSizeFieldWarn)="setSizeFieldWarn($event)"
          shoeSize="{{ shoeSizeFormControl.value }}"
          returnUrl="{{ returnUrl }}"
        >
        </app-measurements-tool>
      </div>
      <div class="desktop-only">
        <div
          class="tool-form-status success"
          *ngIf="form && form.status === 'VALID'"
        >
          <strong>YOU'RE ALL SET! CLICK NEXT TO PROCEED.</strong>
        </div>

        <!-- *ngIf="showMeasurementFormError() && form && form.status !== 'VALID'" -->
        <div
          class="tool-form-status warn"
          *ngIf="
            showMeasurementFormError() &&
            form &&
            form.controls.shoeSize.value == null
          "
        >
          <strong>PLEASE FILL OUT THE REQUIRED MEASUREMENT FIELD</strong>
        </div>
      </div>
      <div class="mobile-only">
        <div
          class="tool-form-status success"
          *ngIf="form && form.status === 'VALID'"
        >
          <strong>YOU'RE ALL SET! CLICK NEXT TO PROCEED.</strong>
        </div>
        <!-- *ngIf="showMeasurementFormError() && form && form.status !== 'VALID'" -->
        <div
          class="tool-form-status warn"
          *ngIf="
            showMeasurementFormError() &&
            form &&
            form.controls.shoeSize.value == null
          "
        >
          <strong>PLEASE FILL OUT THE REQUIRED MEASUREMENT FIELD</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="manual-measurements-radio-container">
    <mat-radio-button
      (click)="
        setDerivedFrom($event, 'manual'); scrollToSkipStepByMemberId(memberId)
      "
      value="manual"
      [checked]="isDerivedFrom('manual')"
      [disabled]="submitted"
    >
      <span class="radio-button-content"
        >I'll enter my own measurements.</span
      ></mat-radio-button
    >
    <p
      style="text-align: start; margin-left: 20px"
      *ngIf="derivedFrom === 'manual' || derivedFrom === 'complete'"
      [ngClass]="measurementFormError ? 'warn' : ''"
    >
      * Required
    </p>
    <form
      *ngIf="derivedFrom === 'manual' || derivedFrom === 'complete'"
      class="manual-measurements-form"
      [formGroup]="form"
    >
      <mat-form-field appearance="outline">
        <mat-label>Height</mat-label>
        <input
          type="text"
          tabindex="0"
          placeholder="Height"
          aria-label="Height"
          matInput
          #heightAutoTrigger="matAutocompleteTrigger"
          formControlName="height"
          requireSelection
          [matAutocomplete]="autoHeight"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #height #autoHeight="matAutocomplete" (closed)="onClose('height')">
          <mat-option
            *ngFor="let option of heightFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Weight (Lbs)</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Weight (Lbs)"
          aria-label="Weight"
          matInput
          formControlName="weight"
          #weightAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, heightAutoTrigger)"
          [matAutocomplete]="autoWeight"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #weight #autoWeight="matAutocomplete" (closed)="onClose('weight')">
          <mat-option
            *ngFor="let option of weightFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!--
      <mat-form-field appearance="outline">
        <mat-label>Coat Size</mat-label>
        <input
          type="text"
          placeholder="Coat Size"
          aria-label="Coat Size"
          matInput
          formControlName="coatSize"
          (blur)="coatBlur()"
          [matAutocomplete]="autoCoatSize"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #coat #autoCoatSize="matAutocomplete">
          <mat-option
            *ngFor="let option of coatSizeFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    -->
      <mat-form-field appearance="outline">
        <mat-label>Chest</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Chest"
          aria-label="Chest"
          matInput
          formControlName="chest"
          #chestAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, weightAutoTrigger)"
          [matAutocomplete]="autoChest"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #chest #autoChest="matAutocomplete" (closed)="onClose('chest')">
          <mat-option
            *ngFor="let option of chestFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Overarm</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Overarm"
          aria-label="Overarm"
          matInput
          formControlName="overarm"
          #overarmAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, chestAutoTrigger)"
          [matAutocomplete]="autoOverarm"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #overarm #autoOverarm="matAutocomplete" (closed)="onClose('overarm')">
          <mat-option
            *ngFor="let option of overarmFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Stomach</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Stomach"
          aria-label="Stomach"
          matInput
          formControlName="stomach"
          #stomachAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, overarmAutoTrigger)"
          [matAutocomplete]="autoStomach"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #stomach #autoStomach="matAutocomplete" (closed)="onClose('stomach')">
          <mat-option
            *ngFor="let option of stomachFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Waist</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Waist"
          aria-label="Waist"
          matInput
          formControlName="waist"
          #waistAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, stomachAutoTrigger)"
          [matAutocomplete]="autoWaist"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #waist #autoWaist="matAutocomplete" (closed)="onClose('waist')">
          <mat-option
            *ngFor="let option of waistFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Hip</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Hip"
          aria-label="Hip"
          matInput
          formControlName="hip"
          #hipAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, waistAutoTrigger)"
          [matAutocomplete]="autoHip"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #hip #autoHip="matAutocomplete" (closed)="onClose('hip')">
          <mat-option
            *ngFor="let option of hipFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Outseam</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Outseam"
          aria-label="Outseam"
          matInput
          formControlName="outseam"
          #outseamAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, hipAutoTrigger)"
          [matAutocomplete]="autoOutseam"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #outseam #autoOutseam="matAutocomplete" (closed)="onClose('outseam')">
          <mat-option
            *ngFor="let option of outseamFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Neck</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Neck"
          aria-label="Neck"
          matInput
          formControlName="neck"
          #neckAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, outseamAutoTrigger)"
          [matAutocomplete]="autoNeck"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #neck #autoNeck="matAutocomplete" (closed)="onClose('neck')">
          <mat-option
            *ngFor="let option of neckFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Sleeve</mat-label>
        <input
          type="number"
          tabindex="0"
          placeholder="Sleeve"
          aria-label="Sleeve"
          matInput
          formControlName="sleeve"
          #sleeveAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, neckAutoTrigger)"
          [matAutocomplete]="autoSleeve"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #sleeve #autoSleeve="matAutocomplete" (closed)="onClose('sleeve')">
          <mat-option
            *ngFor="let option of sleeveFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Shoe Size</mat-label>
        <input
          type="text"
          tabindex="0"
          placeholder="Shoe Size"
          aria-label="Shoe Size"
          matInput
          formControlName="shoeSize"
          #shoeSizeAutoTrigger="matAutocompleteTrigger"
          (focus)="closePreviousPanel($event, sleeveAutoTrigger)"
          [matAutocomplete]="autoShoeSize"
          matAutocompletePosition="below"
        />
        <mat-autocomplete #shoeSize #autoShoeSize="matAutocomplete" (closed)="onClose('shoeSize')">
          <mat-option
            *ngFor="let option of shoeSizeFilteredOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="submitOption" class="save-button-container">
        <strong class="warn">{{ measurementFormError }}</strong>
        <button
          type="button"
          mat-flat-button
          color="primary"
          (click)="saveMeasurements()"
          [disabled]="submitted"
        >
          Submit
        </button>
      </div>
    </form>
  </div>

  <div [ngClass]="memberId" class="skip-measurements-radio-container">
    <mat-radio-button
      *ngIf="skipStepOption"
      (click)="setDerivedFrom($event, 'skip')"
      value="skip"
      [checked]="isDerivedFrom('skip')"
      data-cy="skip-measurements"
      >Skip this step. I'll enter them later.</mat-radio-button
    >
  </div>
</mat-radio-group>
